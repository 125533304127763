import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { setCompanyDetails } from "../../../redux/reducers/companyDetailsSlice";
import webServices from "../../../services/webServices";
import RezdyIntegration from "../../pages/activities/RezdyIntegration";
import ResovaIntegration from "../../pages/activities/ResovaIntegration";
import FareHarborIntegration from "../../pages/activities/FareHarborIntegration";
import CompanyCategory from "../../pages/activities/CompanyCategory";
import CostPerPerson from "../../pages/activities/CostPerPerson";
import ActivityDetails from "../../pages/activities/ActivityDetails";
import Stops from "../../pages/activities/Stops";
import LocationDetails from "../../pages/activities/LocationDetails";
import ExtraVariations from "../../pages/activities/ExtraVariations";
import PageLoader from "../../util/PageLoader";
import AppConstants from "../../../appConstants";
import { authCall } from "../../../services";

const ActivityAdminAction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isShowCompleteListing } = location?.state || {};
  const { current } = useSelector((state) => state.user);
  const companyDetails = useSelector((state) => state.companyDetails);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [step, setStep] = useState(0);
  const [show, setShow] = useState(isShowCompleteListing || false);

  // To disabled back button
  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    const handlePopState = (event) => {
      return false;
      // window.history.pushState(null, "", window.location.href);
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const fetchRestaurantData = async () => {
    const resp2 = await webServices.getCompnayDetails({
      companyType: "Activity",
      // userId: current?.id,
      companyId: current?.company_id,
    });
    if (resp2?.type === "success") {
      const profileLavel = +resp2?.payload?.profile_lavel;
      // if (profileLavel >= 9) {
      //   await webServices.createTour({
      //     bookingId: companyDetails.id,
      //     isProfileComplete: "YES",
      //   });
      //   navigate(AppConstants.routes.admin.activityEditListing);
      // } else {
      setStep(profileLavel || step);
      dispatch(setCompanyDetails(resp2?.payload));
      // }
    }
  };

  useEffect(() => {
    fetchRestaurantData();
  }, [current]);

  const handleSubmit = async (data, stepValue) => {
    setLoading(true);
    const resp = await webServices.createTour({
      ...data,
      bookingId: companyDetails.id,
      ...(stepValue === 10 && {
        isProfileComplete: "YES",
      }),
    });
    if (resp?.type === "success") {
      setLoading(false);
      dispatch(setCompanyDetails(resp?.payload));
      if (stepValue === 10) {
        setStep(0);
        navigate(AppConstants.routes.admin.activityEditListing);
      }
      if (stepValue === 9 && current?.stripe_account_id !== null) {
        navigate(AppConstants.routes.admin.dashboard);
      } else {
        setStep(stepValue);
      }
    }
  };

  const handleClose = () => setShow(false);

  const handleClickDelete = async () => {
    setLoadingDelete(true);
    const resp = await authCall("deleteActivity", {
      bookingId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      setLoadingDelete(true);
      navigate(AppConstants.routes.admin.activityEditListing);
    }
  };

  const renderData = () => {
    switch (step) {
      case 3:
        return (
          <>
            {companyDetails.booking_calendar === "Rezdy" && (
              <RezdyIntegration
                handleSubmit={handleSubmit}
                loadingAdd={loading}
                stepValue={4}
                setStep={setStep}
              />
            )}
            {companyDetails.booking_calendar === "FareHarbor" && (
              <FareHarborIntegration
                handleSubmit={handleSubmit}
                loadingAdd={loading}
                stepValue={4}
                setStep={setStep}
              />
            )}
            {companyDetails.booking_calendar === "Resova" && (
              <ResovaIntegration
                handleSubmit={handleSubmit}
                loadingAdd={loading}
                stepValue={4}
                setStep={setStep}
              />
            )}
          </>
        );
      case 4:
        return (
          <CostPerPerson
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={5}
            setStep={setStep}
          />
        );
      case 5:
        return (
          <CompanyCategory
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={6}
            setStep={setStep}
          />
        );
      case 6:
        return (
          <ActivityDetails
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={8}
            setStep={setStep}
          />
        );
      case 7:
        return (
          <Stops
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={8}
            setStep={setStep}
          />
        );
      case 8:
        return (
          <LocationDetails
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={9}
            setStep={setStep}
            current={current}
          />
        );
      case 9:
        return (
          <ExtraVariations
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={10}
            setStep={setStep}
            current={current}
          />
        );

      default:
        return <PageLoader />;
    }
  };

  return (
    <>
      {renderData()}
      {/* comment on 2025-01-23 */}
      {/* <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold fs-20">
            Keep Your Unfinished Listing?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4 text-center">
          <div className="qr-cover">
            You logged out half way through creating a listing.
          </div>
          <div className="qr-cover">
            If you want to keep the listing click <b>Keep</b>.
          </div>
          <div className="qr-cover">
            If you want to delete the listing click <b>Delete</b>.
          </div>
          <div className="d-flex flex-wrap justify-content-center gap-3 gap-md-5 pt-4">
            <button
              type="submit"
              className="btn btn-custom btn-custom-md"
              onClick={handleClose}
            >
              Keep
            </button>

            <button
              type="submit"
              className="btn btn-custom btn-custom-md"
              onClick={handleClickDelete}
              disabled={loadingDelete || false}
            >
              {loadingDelete ? "Deleting..." : "Delete"}
            </button>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default ActivityAdminAction;
