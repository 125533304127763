import toastr from "toastr";
import authService from "./authServices";

const axios = require("axios").default;

axios.defaults.baseURL = 'https://touch-it.com.au/api/public/api/v1/';
// axios.defaults.baseURL = "http://localhost/touchit-backend/public/api/v1/";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Token = `${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error

    toastr.error("ERROR! Not able to send any request due to an exception.");
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    if (response.data.type !== "success") {
      toastr.error("ERROR! Internal Server side exception has occoured.");
    }
    return response;
  },
  (err) => {
    if (err?.response) {
      if (err?.response?.data) {
        if (err?.response?.data?.message) {
          toastr.remove();
          toastr.clear();
          toastr.error(`${err.response.data.message}`);
        } else {
          toastr.error("ERROR! Server side exception has occoured.");
        }
      } else {
        toastr.error("ERROR! An exception has occoured.");
      }
    } else if (err.request) {
      toastr.error(
        "ERROR! Server is not responding, please check your internet connection."
      );
    } else {
      toastr.error(
        "ERROR! Not able to complete the request due to an exception."
      );
    }
    return Promise.reject(err);
  }
);

export const authCall = async (url, body) => {
  let result;
  await axios
    .post(url, body)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => error);
  return result;
};

export const fetchData = async (url) => {
  let result;
  await axios
    .get(url)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => error);
  return result;
};

export const postRequest = (url, data) =>
  axios
    .post(url, data)
    .then((response) => response.data)
    .catch((error) => error);

export const putRequest = (url, dataToSend) =>
  axios
    .put(url, dataToSend)
    .then((res) => res.data)
    .catch((error) => error);