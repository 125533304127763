import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { EyeFill, Plus, PencilSquare, Trash } from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";
import { handleNumericInput } from "../../util/utils";

function NightlifeEventListPopup({ show, handleClose, selectedNightlifeId }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { current } = useSelector((state) => state.user);
  const [typeName, setTypeName] = useState("");
  const [evenList, setEvenList] = useState([]);

  const eventListFun = async () => {
    if (selectedNightlifeId) {
      const resp = await webServices.getNightLifeEvent(selectedNightlifeId);
      if (resp?.type === "success") {
        setEvenList(resp?.payload);
        setLoading(false);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    eventListFun();
  }, [selectedNightlifeId]);

  const handleChangeStatus = async (id, status) => {
    if (current?.user_type === "Admin") {
      const resp = await authCall("changeEventStatus", { id, status });
      if (resp?.type === "success") {
        eventListFun();
      }
    }
  };

  const handleEdit = (item) => {
    navigate(AppConstants.routes.admin.updateEventDtails, { state: { item } });
  };

  const handleAdd = () => {
    navigate(AppConstants.routes.admin.updateEventDtails, {
      state: { item: { nightLifeId: selectedNightlifeId } },
    });
  };

  const handleDelete = (el) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(el)}
        >
          Yes
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (item) => {
    const resp = await authCall("changeEventStatus", {
      id: item.id,
      deletedAt: moment(),
    });
    if (resp?.type === "success") {
      closeToast();
      toast.success("Deleted successfully.");
      eventListFun();
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  return (
    <>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Nightlife Events</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-field-wrapper p-3">
          <div className="table-responsive common-info-card border-0">
            <Row className="align-items-center mb-4">
              <Col xs={12} className="text-end">
                <Button
                  className="btn btn-custom btn-custom-sm fs-16"
                  onClick={handleAdd}
                >
                  <Plus />
                  Add Event
                </Button>
              </Col>
            </Row>
            <table className="table table-borderless table-custom m-0">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Event Name</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Date</th>
                  <th scope="col">Start Time</th>
                  <th scope="col">End Time</th>
                  <th scope="col">Recurring</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  (evenList.length > 0 ? (
                    evenList.map((el, index) => (
                      <tr key={index}>
                        <td>{el.id}</td>
                        <td>{el.event_name}</td>
                        <td>${el.event_cost}</td>
                        <td>
                          {moment(el.event_start_date, "YYYY-mm-DD").format(
                            "YYYY-mm-DD"
                          )}
                        </td>
                        <td>
                          {moment(el.event_start_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </td>
                        <td>
                          {moment(el.event_end_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </td>
                        <td>{el.event_recurring}</td>
                        <td className="text-center">
                          <Badge
                            bg={el.status === "1" ? "success" : "danger"}
                            className="cursor-pointer"
                            onClick={() =>
                              handleChangeStatus(
                                el.id,
                                el.status === "1" ? "0" : "1"
                              )
                            }
                          >
                            {el.status === "1" ? "Active" : "In-active"}
                          </Badge>
                        </td>
                        <td>
                          <div className="icon-container">
                            <PencilSquare
                              onClick={() => handleEdit(el)}
                              className="cursor-pointer"
                              style={{
                                color: "blue",
                              }}
                            />
                            <Trash
                              onClick={() => handleDelete(el)}
                              className="cursor-pointer"
                              style={{
                                color: "red",
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={9} className="text-center">
                        No record found
                      </td>
                    </tr>
                  ))}
                {loading && (
                  <tr>
                    <td colSpan={9} className="text-center">
                      Loading...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NightlifeEventListPopup;
