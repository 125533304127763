import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import webServices from "../../services/webServices";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import AppConstants from "../../appConstants";
import BannerComponent from "../BannerComponent";
import AvailabilityCalendar from "./AvailabilityCalendar";

function Tour() {
  const navigate = useNavigate();
  const location = useLocation();
  const { activityId } = location.state || {};
  const [details, setDetails] = useState({});
  const [highlights, setHighlights] = useState([]);
  const [exclusions, setExclusions] = useState([]);
  const [priceOptions, setPriceOptions] = useState([]);
  const [loading, setLoading] = useState([]);
  const [highSkelArray] = useState(new Array(6).fill());
  const [exclSkelArray] = useState(new Array(3).fill());
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPeople, setTotalPeople] = useState(0);
  const [searchAvail, setSearchAvail] = useState(false);
  const [available, setAvailable] = useState([]);
  const [selectedDT, setSelectedDT] = useState(null); // moment().format("Y-MM-DD")
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showRefundPolicy, setShowRefundPolicy] = useState(false);
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [groupPrice, setGroupPrice] = useState([]);
  const [notAvailable, setNotAvailable] = useState(false);
  const [startTimeLocal, setStartTimeLocal] = useState(null);
  const [endTimeLocal, setEndTimeLocal] = useState(null);
  const [banner, setBanner] = useState([]);

  // For Bootstrap Model
  const openAdditionalModal = () => setShowAdditionalInfo(true);
  const closeAdditionalModal = () => setShowAdditionalInfo(false);

  const openRefundModal = () => setShowRefundPolicy(true);
  const closeRefundModal = () => setShowRefundPolicy(false);

  const handleClickNext = () => {
    toast.dismiss();
    if (totalPeople === 0) {
      toast.error("Please enter number of people.");
    } else if (!selectedDT) {
      toast.error("Please select booking date.");
    } else if (!startTimeLocal && !endTimeLocal) {
      toast.error("Please select booking time slot.");
    } else {
      const bookingData = {
        startTimeLocal,
        endTimeLocal,
        totalPeople,
        totalAmount,
        priceOptions,
      };
      navigate(AppConstants.routes.tour.bookingCheckout, {
        state: { details: bookingData },
      });
      // setShowMeetingModal(true);
    }
  };

  const openMeetingModal = () => {
    console.log("detailsdetails", details);
    setShowMeetingModal(true);
  };

  const closeMeetingModal = () => setShowMeetingModal(false);

  useEffect(() => {
    if (!activityId) {
      navigate(AppConstants.routes.home, { replace: true });
    }
  }, [activityId, navigate]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await webServices.getTour(activityId);
      if (resp.type === "success") {
        const details = resp?.payload;
        setDetails(details);
        setBanner(details?.bannerImages);
        setHighlights(details?.highlight_includes_new);
        setExclusions(details?.highlight_excludes_new);
        const prices = details?.price_options || [];
        const groupPrices = [];
        prices.map((price) => {
          if (price.isGroup && price.isDisplay) {
            groupPrices.push(price);
          }
        });
        setPriceOptions(details?.price_options);
        setGroupPrice(groupPrices);
        setLoading(false);
      }
    }
    fetchData();
    getSum();
  }, [activityId]);

  useEffect(() => {
    getSum();
  }, [priceOptions]);

  const handleIncrement = (val) => {
    const { id } = val;
    setPriceOptions(
      priceOptions.map((field) =>
        field.id === id
          ? { ...field, quantity: (field.quantity || 0) + 1 }
          : field
      )
    );

    setAvailable([]);
    setNotAvailable(false);
  };

  const handleDecrement = (val) => {
    const { id } = val;
    setPriceOptions(
      priceOptions.map((field) =>
        field.id === id && field.quantity > 0
          ? { ...field, quantity: (field.quantity || 0) - 1 }
          : field
      )
    );

    setAvailable([]);
    setNotAvailable(false);
  };

  const getSum = () => {
    // total price
    const total = priceOptions.reduce(
      (sum, item) => sum + (item.quantity ? item.price * item.quantity : 0),
      0
    );
    setTotalAmount(total);

    // total people
    const totalPeople = priceOptions.reduce(
      (sum, item) => sum + (item.quantity ? item.quantity : 0),
      0
    );
    setTotalPeople(totalPeople);
  };

  const handleSearchAvailability = async (date) => {
    if (!date) {
      toast.dismiss();
      toast.error("Booking date not selected.");
      setSelectedDT(null);
    } else {
      // const date = e.target.value;
      console.log(date);
      setSelectedDT(date);
      setAvailable([]);
      setNotAvailable(false);
      if (date) {
        setSearchAvail(true);
        const sarachData = {
          productCode: details?.activity_code, // 'PKRQJD'
          startTimeLocal: `${date} 00:00:00`,
          endTimeLocal: `${date}  23:59:59`,
          minAvailability: totalPeople,
        };
        const resp = await webServices.searchAvailability(sarachData);
        if (resp.type === "success") {
          setAvailable(resp?.payload?.sessions);
          setSearchAvail(false);
          if (resp?.payload?.sessions.length === 0) {
            setNotAvailable(true);
          }
        }
      }
    }
  };

  const handleClickBookingTime = (startTime, endTime) => {
    setStartTimeLocal(startTime);
    setEndTimeLocal(endTime);
  };

  const selectedDate = (date) => {
    handleSearchAvailability(date);
  };
  return (
    <>
      <div className="booking-details long-screen-page">
        {/* BANNER */}
        <BannerComponent
          banner={banner}
          companyName={details.company_name}
          title={details.activity_name}
          subTitle="Surfers Paradise"
          button={{ title: "Back" }}
        />

        <section className="booking-info pt-4 pb-5">
          <div className="container-fluid" style={{ maxWidth: "1010px" }}>
            <div className="row">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex flex-column custom-left-sec-height">
                  <div
                    className="bg-box overflow-auto p-3 p-sm-4 mb-4"
                    // style={{ flex: 1 }}
                  >
                    <div className="heading main-heading2 mb-2">Overview</div>
                    <div className="description fs-md-18">
                      {loading &&
                        highSkelArray.map((_, index) => (
                          <Skeleton key={index} count={1} />
                        ))}

                      {/* {!loading && details.overview} */}
                      {!loading && (
                        <div
                          className="text-break"
                          dangerouslySetInnerHTML={{ __html: details.overview }}
                        />
                      )}
                    </div>
                  </div>
                  {highlights && (
                    <div className="bg-box highlights-box p-3 p-sm-4 mb-4">
                      <div className="heading main-heading2 mb-2">
                        Highlights & inclusions
                      </div>
                      <div className="wrapper">
                        {loading &&
                          highSkelArray.map((_, index) => (
                            <Skeleton key={index} count={1} width={280} />
                          ))}

                        {!loading &&
                          highlights.map((el, index) => (
                            <div
                              key={index}
                              className="d-flex gap-3 fs-md-18 mt-2"
                            >
                              <i className="bi bi-check-circle text-custom-primary"></i>
                              {el.value}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  {exclusions && (
                    <div className="bg-box eexclusion-box p-3 p-sm-4">
                      <div className="heading main-heading2 mb-2">
                        Exclusions
                      </div>
                      <div className="wrapper">
                        {loading &&
                          exclSkelArray.map((_, index) => (
                            <Skeleton key={index} count={1} width={280} />
                          ))}
                        {!loading &&
                          exclusions.map((el, index) => (
                            <div
                              key={index}
                              className="d-flex gap-3 fs-md-18 mt-2"
                            >
                              <i className="bi bi-x-circle text-red"></i>
                              {el.value}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="reservation-formm booking-form">
                  <div className="text-center">
                    <div className="main-heading2 label-heading">Book Now</div>
                  </div>

                  <div className="wrapper p-3 p-lg-4">
                    <div className="cover-box mb-3">
                      <div
                        className="person-wrapper overflow-auto"
                        style={{ maxHeight: "220px" }}
                      >
                        {loading &&
                          exclSkelArray.map((_, index) => (
                            <div
                              key={index}
                              className="persons-info d-flex align-items-center justify-content-between gap-2"
                            >
                              <div className="info-sec">
                                <div className="fs-18 fw-semibold">
                                  <Skeleton count={1} width={200} />
                                </div>
                              </div>
                              <div className="counter-box d-flex align-items-center justify-content-center">
                                <button type="button" className="sub">
                                  <i className="bi bi-caret-left-fill"></i>
                                </button>
                                <input type="text" value="0" />
                                <button type="button" className="add">
                                  <i className="bi bi-caret-right-fill"></i>
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>

                      <div
                        className="person-wrapper overflow-auto"
                        style={{ maxHeight: "220px" }}
                      >
                        {!loading &&
                          priceOptions.map(
                            (el, index) =>
                              !el.isGroup &&
                              el.isDisplay === true && (
                                <div
                                  key={index}
                                  className="persons-info d-flex align-items-center justify-content-between gap-2"
                                >
                                  <div className="info-sec">
                                    <div className="fs-18 fw-semibold">
                                      {el?.touchIdLabel
                                        ? el?.touchIdLabel
                                        : el.label}
                                    </div>
                                  </div>
                                  <div className="counter-box d-flex align-items-center justify-content-center">
                                    <button
                                      type="button"
                                      id="sub1"
                                      className="sub"
                                      onClick={() => handleDecrement(el)}
                                    >
                                      <i className="bi bi-caret-left-fill"></i>
                                    </button>
                                    <input
                                      type="text"
                                      id="1"
                                      value={el.quantity || 0}
                                      min="0"
                                      max="6"
                                      name="adult"
                                    />
                                    <button
                                      type="button"
                                      id="add1"
                                      className="add"
                                      onClick={() => handleIncrement(el)}
                                    >
                                      <i className="bi bi-caret-right-fill"></i>
                                    </button>
                                  </div>
                                </div>
                              )
                          )}
                      </div>

                      <div className="cover-wrapper mt-3 mt-lg-4">
                        {groupPrice.length > 0 && (
                          <div className="main-heading2 fw-bold text-black text-center text-uppercase mb-2">
                            Group Discounts
                          </div>
                        )}
                        <div
                          className="person-wrapper overflow-auto"
                          style={{ maxHeight: "150px" }}
                        >
                          {priceOptions.map(
                            (el, index) =>
                              el.isGroup === true &&
                              el.isDisplay === true && (
                                <div
                                  key={index}
                                  className="persons-info d-flex align-items-center justify-content-between gap-2"
                                  style={{ backgroundColor: "#000" }}
                                >
                                  <div className="info-sec">
                                    <div className="fs-18 fw-semibold">
                                      {el?.touchIdLabel
                                        ? el?.touchIdLabel
                                        : el.label}
                                    </div>
                                  </div>
                                  <div className="counter-box d-flex align-items-center justify-content-center">
                                    <button
                                      type="button"
                                      id="sub3"
                                      className="sub"
                                      onClick={() => handleDecrement(el)}
                                    >
                                      <i className="bi bi-caret-left-fill"></i>
                                    </button>
                                    <input
                                      type="text"
                                      value={el.quantity || 0}
                                    />
                                    <button
                                      type="button"
                                      id="add3"
                                      className="add"
                                      onClick={() => handleIncrement(el)}
                                    >
                                      <i className="bi bi-caret-right-fill"></i>
                                    </button>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Booking calendar */}
                    <AvailabilityCalendar
                      selectedDate={selectedDate}
                      totalPeople={totalPeople}
                      activityCode={details?.activity_code}
                    />

                    <div className="booking-calendar position-relative mb-3 d-none">
                      <input
                        type="date"
                        className="dates date-pointer"
                        autoComplete="off"
                        name="startDate"
                        value={selectedDT ? selectedDT : ""}
                        min={moment().format("Y-MM-DD")}
                        onChange={(e) => handleSearchAvailability(e)}
                      />
                    </div>

                    {searchAvail ? (
                      <div className="fw-medium d-flex align-items-center justify-content-center gap-2 text-danger">
                        Searching...{" "}
                        <Spinner animation="border" variant="warning" />
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className="check-wrapper d-flex flex-wrap gap-2 overflow-auto mb-3 mt-2"
                      style={{ maxHeight: "100px" }}
                    >
                      {available.length > 0 &&
                        available.map((el) => {
                          const startTime = moment
                            .utc(el?.startTimeLocal)
                            .format("HH:mm");
                          const endTime = moment
                            .utc(el?.endTimeLocal)
                            .format("HH:mm");
                          return (
                            <div className="d-flex align-items-center gap-1">
                              <input
                                type="radio"
                                name="timeslot"
                                className="custom-radio m-0 cursor-pointer"
                                onChange={() =>
                                  handleClickBookingTime(
                                    el?.startTimeLocal,
                                    el?.endTimeLocal
                                  )
                                }
                              />
                              <label
                                className="bg-danger rounded-2"
                                htmlFor="Morning"
                              >
                                {startTime === "00:00"
                                  ? "No fixed time. Available all day"
                                  : `${startTime}-${endTime}`}
                              </label>
                            </div>
                          );
                        })}
                      {notAvailable && (
                        <div className="search-status">
                          <div className="fs-6 fw-medium text-danger lh-sm">
                            Unfortunately, there are no available trips on the
                            selected day OR the group is too big for the
                            available spots. Please select another day OR Please
                            break your group into smaller groups.
                          </div>
                        </div>
                      )}

                      {/* <div className="search-status">
                          <div className="fs-6 fw-medium text-danger lh-sm">
                            Unfortunately, the group is too big for the available spots. Please break your group into smaller groups, or select another day.
                          </div>
                        </div> */}
                    </div>

                    <div className="price-box d-flex align-items-center justify-content-between rounded-1 mb-3">
                      <div className="total-amount flex-fill">Total</div>
                      <div className="amount-value flex-fill">
                        <sup>$</sup>
                        {totalAmount}
                      </div>
                    </div>

                    <div className="btn-wrapper">
                      <button
                        type="submit"
                        className="booking-btn"
                        name="submit"
                        onClick={handleClickNext}
                      >
                        NEXT
                      </button>
                    </div>

                    <div className="modal-cover d-flex flex-wrap justify-content-between gap-2 mt-3">
                      <button
                        type="button"
                        className="btn btn-link border-0 p-0"
                        onClick={openAdditionalModal}
                      >
                        Additional Information
                      </button>
                      <button
                        type="button"
                        className="btn btn-link border-0 p-0"
                        onClick={openRefundModal}
                      >
                        Cancellation Policy
                      </button>
                    </div>

                    <div className="review-box text-center d-none">
                      <div className="review-stars d-flex gap-2">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-half"></i>
                      </div>
                      <div className="rating d-flex align-items-center justify-content-center gap-2">
                        <div className="m-0">(4.6)</div>
                        <div className="m-0">100 Reviews</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* pickup locations */}
        <section className="meeting-and-pickup pb-2">
          <div className="container-fluid" style={{ maxWidth: "1010px" }}>
            <div className="bg-box overview p-3 p-sm-4">
              <div
                className="heading main-heading2 text-center mb-4"
                style={{ display: "block" }}
              >
                Meeting And Pickup
              </div>
              <div className="row gx-3 justify-content-center">
                <div className="col-12 col-md-4 mb-4 mb-md-0">
                  <div className="bg-box overview h-100 p-3 py-4 px-lg-4">
                    <div className="main-heading2 text-uppercase mb-3">
                      Address
                    </div>
                    <address className="mb-0">
                      {details?.location?.addressLine}
                      <br />
                      {details?.location?.city}
                      <br />
                      {details?.location?.state?.label}{" "}
                      {details?.location?.postCode}
                      <br />
                    </address>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 mb-4 mb-sm-0 d-none">
                  <div className="bg-box overview h-100 p-3 py-4 px-lg-4">
                    <div className="main-heading2 text-uppercase mb-3">
                      Pickup Points
                    </div>
                    <address>
                      Marriot Hotel,
                      <br />
                      123 Main Street,
                      <br />
                      Surfers Paradise
                    </address>
                    <address className="mb-0">
                      Hilton Hotel,
                      <br />
                      159 South Street,
                      <br />
                      Surfers Paradise
                    </address>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 d-none">
                  <div className="bg-box overview h-100 p-3 py-4 px-lg-4">
                    <div className="main-heading2 text-uppercase mb-3">
                      Drop off location
                    </div>
                    <address>
                      Marriot Hotel,
                      <br />
                      123 Main Street,
                      <br />
                      Surfers Paradise
                    </address>
                    <address className="mb-0">
                      Hilton Hotel,
                      <br />
                      159 South Street,
                      <br />
                      Surfers Paradise
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Additional Information Modal */}
        <Modal
          show={showAdditionalInfo}
          centered
          size="lg"
          onHide={closeAdditionalModal}
          backdrop="static"
          keyboard={false}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">
              Additional Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="wrapper">
              <ul className="ps-3 mb-0">
                <li className="mb-2">
                  Confirmation will be received within 48 hours of booking,
                  subject to availability{" "}
                </li>
                <li className="mb-2">
                  MUST contact us to change pick up locations
                </li>
                <li className="mb-2">Not wheelchair accessible </li>
                <li className="mb-2">
                  This tour is not recommended for those with walking
                  disabilities or using a wheelchair{" "}
                </li>
                <li className="mb-2">
                  This tour is not suitable for young infants under 4 years old{" "}
                </li>
                <li className="mb-2">
                  Not recommended for travelers with back problems{" "}
                </li>
                <li className="mb-2">Not recommended for pregnant travelers</li>
                <li className="mb-2">
                  No heart problems or other serious medical conditions{" "}
                </li>
                <li className="mb-2">Most travelers can participate </li>
                <li className="mb-2">
                  No Refund for Covid No Show; please purchase insurance{" "}
                </li>
                <li className="mb-2">
                  Office hours are 9am - 5pm, you must contact us by phone after
                  work hours.{" "}
                </li>
                <li className="mb-0">
                  This tour/activity will have a maximum of 20 travelers
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        {/* Refund Policy Modal */}
        <Modal
          show={showRefundPolicy}
          centered
          size="lg"
          onHide={closeRefundModal}
          backdrop="static"
          keyboard={false}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Refund Policy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="wrapper">
              <p>
                You can cancel up to 24 hours in advance of the experience for a
                full refund.
              </p>
              <ul className="ps-3 mb-0">
                <li className="mb-3">
                  For a full refund, you must cancel at least 24 hours before
                  the experience’s start time.
                </li>
                <li className="mb-3">
                  If you cancel less than 24 hours before the experience’s start
                  time, the amount you paid will not be refunded.{" "}
                </li>
                <li className="mb-3">
                  Any changes made less than 24 hours before the experience’s
                  start time will not be accepted.
                </li>
                <li className="mb-3">
                  Cut-off times are based on the experience’s local time.{" "}
                </li>
                <li className="mb-3">
                  This experience requires good weather. If it’s canceled due to
                  poor weather, you’ll be offered a different date or a full
                  refund.{" "}
                </li>
                <li className="mb-0">
                  This experience requires a minimum number of travelers. If
                  it’s canceled because the minimum isn’t met, you’ll be offered
                  a different date/experience or a full refund.
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        {/* Droff-off Modal */}
        <Modal
          show={showMeetingModal}
          centered
          size="lg"
          onHide={closeMeetingModal}
          backdrop="static"
          keyboard={false}
          dialogClassName="custom-modal custom-model-2 meetingPopup modal-full-mobile"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">
              Select Meeting & Drop Off Point:
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="filter-wrapper">
              <form action="">
                <div className="row d-flex flex-wrap">
                  <div className="col-md-6 mb-4">
                    <div className="bg-box h-100 p-3 p-md-4">
                      <div className="main-heading2 mb-3">
                        Select Pick Up Point:
                      </div>
                      <div className="form-group d-flex gap-3 pb-lg-1">
                        <input
                          type="radio"
                          className="custom-radio"
                          name="pickupPoint"
                          id="radio1"
                        />
                        <label className="m-0" htmlFor="radio1">
                          <div className="fs-18 fw-medium mb-2">
                            Cairns Marlin Marina
                          </div>
                          <div className="desc">
                            1 Spence St, Cairns City
                            <br />
                            QLD 4870, Australia
                            <br />
                            Cairns Marina - B Finger
                          </div>
                        </label>
                      </div>
                      <div className="form-group d-flex gap-3">
                        <input
                          type="radio"
                          className="custom-radio"
                          name="pickupPoint"
                          id="radio2"
                        />
                        <label className="m-0" htmlFor="radio2">
                          <div className="fs-18 fw-medium mb-2">
                            Cairns Marlin Marina
                          </div>
                          <div className="desc">
                            1 Spence St, Cairns City
                            <br />
                            QLD 4870, Australia
                            <br />
                            Cairns Marina - B Finger
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-4">
                    <div className="bg-box h-100 p-3 p-md-4">
                      <div className="main-heading2 mb-3">
                        Select Drop Off Point:
                      </div>
                      <div className="form-group d-flex gap-3">
                        <input
                          type="radio"
                          className="custom-radio"
                          name="dropPoint"
                          id="radio3"
                        />
                        <label className="m-0" htmlFor="radio3">
                          <div className="fs-18 fw-medium mb-2">
                            Cairns Marlin Marina
                          </div>
                          <div className="desc">
                            1 Spence St, Cairns City
                            <br />
                            QLD 4870, Australia
                            <br />
                            Cairns Marina - B Finger
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="product-details">
                  <div className="bg-box p-3 p-md-4">
                    <div className="main-heading2 mb-3">Select Extra’s:</div>
                    <div className="form-group d-flex gap-3 mb-4">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        name="selectExtra1"
                        id="radiogroup1"
                      />
                      <label className="flex-fill m-0" htmlFor="radiogroup1">
                        <div className="product-info d-flex flex-wrap gap-3 gap-md-4">
                          <div className="product-img">
                            <img
                              src={require("../../assets/images/site/product-1.png")}
                              alt="Product"
                              className="img-fluid"
                            />
                          </div>
                          <div className="product-info d-flex flex-wrap gap-3 flex-fill">
                            <div className="me-2 flex-fill">
                              <div className="product-name fs-18 fw-medium mb-1">
                                Traditional Handmade Apron
                              </div>
                              <div className="product-desc">
                                Cook in style with
                              </div>
                            </div>
                            <div className="product-price text-custom-primary lh-1">
                              $50
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="form-group d-flex gap-3 mb-0">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        name="selectExtra2"
                        id="radiogroup2"
                      />
                      <label className="flex-fill m-0" htmlFor="radiogroup2">
                        <div className="product-info d-flex flex-wrap gap-3 gap-md-4">
                          <div className="product-img">
                            <img
                              src={require("../../assets/images/site/product-2.png")}
                              alt="Product"
                              className="img-fluid"
                            />
                          </div>
                          <div className="product-info d-flex flex-wrap gap-3 flex-fill">
                            <div className="me-2 flex-fill">
                              <div className="product-name fs-18 fw-medium mb-1">
                                Traditional Handmade Apron
                              </div>
                              <div className="product-desc">
                                Cook in style with
                              </div>
                            </div>
                            <div className="product-price text-custom-primary lh-1">
                              $50
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="btn-wrapper text-center mt-4">
                  <button type="submit" className="btn btn-custom">
                    Book
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Tour;
