import React from "react";

const StarRating = ({ rating }) => {
  const maxStars = 5; // Total number of stars

  // Calculate full stars, half stars, and empty stars
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = maxStars - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <span className="stars">
      {/* Render full stars */}
      {[...Array(fullStars)].map((_, index) => (
        <i key={`full-${index}`} className="fa fa-star"></i>
      ))}
      {/* Render half star */}
      {hasHalfStar && <i className="fa fa-star-half-o"></i>}
      {/* Render empty stars */}
      {[...Array(emptyStars)].map((_, index) => (
        <i key={`empty-${index}`} className="fa fa-star-o"></i>
      ))}
      <span style={{ color: "#fff" }}> ({rating})</span>
    </span>
  );
};

export default StarRating;
