import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { EyeFill, PencilSquare, Trash } from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { authCall } from "../../../services";
import webServices from "../../../services/webServices";
import { generateSku } from "../../util/utils";
import ImageGridModal from "./ImageGridModal";
import { handleNumericInput } from "../../util/utils";
import AppConstants from "../../../appConstants";
import NightlifeEventListPopup from "./NightlifeEventListPopup";

const customStyles = {
  control: (base) => ({
    ...base,
    height: "30px", // Set the height
    minHeight: "30px", // Ensure the minimum height
    minWidth: "160px",
    marginTop: "-4px",
    padding: "0 1px", // Adjust padding
    display: "flex",
    alignItems: "center", // Center content vertically
    backgroundColor: "#bababa",
    border: "2px solid #fff",
    color: "var(--black)",
    borderRadius: "5px",
    boxShadow: "none",
    // "&:hover": {
    //   borderColor: "#888",
    // },
  }),
  placeholder: (base) => ({
    ...base,
    display: "flex",
    alignItems: "center", // Center placeholder vertically
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: "30px",
    display: "flex",
    alignItems: "center", // Center arrow vertically
  }),
  valueContainer: (base) => ({
    ...base,
    height: "30px",
    padding: "0 8px",
    marginTop: "-4px",
    display: "flex",
    fontSize: 14,
    alignItems: "center", // Center selected value vertically
  }),
  singleValue: (base) => ({
    ...base,
    display: "flex",
    alignItems: "center",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#bababa", // Custom background for the dropdown menu
    zIndex: 999999,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "#1967d4" //"#007bff"
      : state.isFocused
      ? "#e0e0e0"
      : "#bababa",
    color: state.isSelected ? "#fff" : "#333",
    padding: "2px",
    paddingLeft: "6px",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#1967d4",
      color: "#fff",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#212529", // Change the color of the dropdown arrow
    transform: "scale(0.9)",
    color: "var(--black)",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none", // Remove the separator between the arrow and the select field
  }),
};

const COMPANYTYPE = [
  { value: "1", label: "Booking" },
  { value: "2", label: "Restaurant" },
  { value: "3", label: "Nightlife" },
];

// Booking tags list
const BOOKING_SUB_PAGE_CAT = () => [
  { value: "1", label: "Adventure Tours" },
  { value: "2", label: "Fun & Games" },
  { value: "3", label: "Hot Deals" },
  { value: "4", label: "Zoo & Aquariums" },
  { value: "5", label: "New Experiences" },
  { value: "6", label: "Outdoor Activities" },
  { value: "7", label: "Reef & Island Cruises" },
  { value: "8", label: "Top Deals" },
  { value: "9", label: "Top Experiences" },
];

const BOOKING_CITY_PAGE_CAT = () => [
  { value: "1", label: "Adelaide" },
  { value: "2", label: "Brisbane" },
  { value: "3", label: "Cairns" },
  { value: "4", label: "Gold Coast" },
  { value: "5", label: "Hobart" },
  { value: "6", label: "Port Duglas" },
  { value: "7", label: "Perth" },
  { value: "8", label: "Sunshine Coast" },
  { value: "9", label: "Sydney" },
  { value: "10", label: "Whitesundays" },
];

// Restaurant tags list
const RESTAURANT_SUB_PAGE_CAT = () => [
  { value: "1", label: "International Cuisine" },
  { value: "2", label: "Australian Cuisine" },
  { value: "3", label: "Mediterranean Cuisine" },
  { value: "4", label: "Indian Cuisine" },
  { value: "5", label: "Thai Cuisine" },
  { value: "6", label: "Asian Cuisine" },
  { value: "7", label: "Ice Cream" },
  { value: "8", label: "Cafe Dining" },
  { value: "9", label: "Popular Eats" },
];

// Night Life tags list
const NIGHT_SUB_PAGE_CAT = () => [
  { value: "1", label: "Beer Garden" },
  { value: "2", label: "Cocktail Bars" },
  { value: "3", label: "Comedy Club" },
  { value: "4", label: "Hotel Bar" },
  { value: "5", label: "Irish Pubs" },
  { value: "6", label: "Karaoke Bar" },
  { value: "7", label: "Nightclub" },
  { value: "8", label: "Specality Bar" },
  { value: "9", label: "Sports Bar" },
  { value: "10", label: "Wine Bar" },
];

function TagsList() {
  const navigate = useNavigate();
  const [tagList, setTagsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(COMPANYTYPE[0]);
  const [subPageList, setSubPageList] = useState([]);
  const [cityPageList, setCityPageList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [activityType, setActivityType] = useState("");
  const [activityID, setActivityID] = useState("");
  const [show, setShow] = useState(false);
  const [filterUserList, setFilterUserList] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState({});
  const [showNightlifeEvent, setShowNightlifeEvent] = useState(false);
  const [selectedNightlifeId, setSelectedNightlifeId] = useState("");
  const [isNightlifeMenuAvailable, setIsNightlifeMenuAvailable] = useState("");

  const getTagsList = async (tag, status = false) => {
    if (!status) {
      setLoading(true);
    }

    const resp = await authCall("getCompanyTagList", {
      tags: tag,
    });
    if (resp?.type === "success") {
      setTagsList(resp?.payload);
      setLoading(false);

      if (tag === "Restaurant") {
        // Initialize selected menus
        const menulist = resp?.payload;
        const initialSelection2 = menulist.reduce((acc, restaurant) => {
          acc[restaurant.id] = {
            breakfast:
              restaurant?.menus?.Breakfast?.find(
                (menu) => menu.front_page_tag > 0
              )?.id || null,
            lunch:
              restaurant?.menus?.Lunch?.find((menu) => menu.front_page_tag > 0)
                ?.id || null,
            dinner:
              restaurant?.menus?.Dinner?.find((menu) => menu.front_page_tag > 0)
                ?.id || null,
            all_day_menu:
              restaurant?.menus?.all_day_menu?.find(
                (menu) => menu.front_page_tag > 0
              )?.id || null,
          };
          return acc;
        }, {});
        setSelectedMenus(initialSelection2);
      }
    }
  };

  useEffect(() => {
    setSubPageList(BOOKING_SUB_PAGE_CAT);
    setCityPageList(BOOKING_CITY_PAGE_CAT);
    getTagsList("Booking");
  }, []);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    getTagsList(selected.label);
    if (selected.label === "Restaurant") {
      setSubPageList(RESTAURANT_SUB_PAGE_CAT);
    } else if (selected.label === "Nightlife") {
      setSubPageList(NIGHT_SUB_PAGE_CAT);
    } else {
      setSubPageList(BOOKING_SUB_PAGE_CAT);
    }
  };

  // Handler for selection change
  const handleChangeSubPage = async (selected, id) => {
    if (selected.length > 0) {
      selected.map((el) => (el.sku = generateSku(el?.label)));
      const updatedItems = tagList.map((item) =>
        item.id === id ? { ...item, sku: selected } : item
      );
      setTagsList(updatedItems);
      await webServices.createTour({
        sku: selected,
        bookingId: id,
      });
    }
  };

  const handleChangeStatus = async (tagType, value, id) => {
    const params = {
      [tagType]: value,
      ...(tagType === "subPageTag" && {
        sku: generateSku(value),
      }),
      ...(tagType === "cityPageTag" && {
        citySku: generateSku(value),
      }),
    };

    if (selectedOption.label === "Restaurant") {
      await webServices.createRestaurant({
        ...params,
        restaurantId: id,
      });
    } else if (selectedOption.label === "Nightlife") {
      await webServices.createNightLife({
        ...params,
        nightLifeId: id,
      });
    } else {
      await webServices.createTour({
        ...params,
        bookingId: id,
      });
    }

    if (tagType === "frontPageTag") {
      const updatedItems = tagList.map((item) =>
        item.id === id ? { ...item, front_page_tag: value } : item
      );
      setTagsList(updatedItems);
    }
  };

  const handleShowModal = (items) => {
    setImages(items);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  const handleMakePrimary = (image) => {
    handleChangeStatus("bannerTags", image.bannerName, image.id);
  };

  const handleChangeStatusValue = (id, newStatus) => {
    const updatedItems = tagList
      .map((item) => (item.id === id ? { ...item, status: newStatus } : item))
      .sort((a, b) => a.status.localeCompare(b.status));

    setTagsList(updatedItems);
    handleChangeStatus("status", newStatus, id);
  };

  const handleUpdateFields = async (params, id) => {
    if (selectedOption.label === "Booking") {
      await webServices.createTour({
        ...params,
        bookingId: id,
      });
    }
  };

  // add commisions to bookign and users
  const handleAddCommission = (id, val) => {
    const inputValue = handleNumericInput(val);
    if (inputValue !== null && inputValue.length < 4) {
      setTagsList(
        tagList.map((field) =>
          field.id === id ? { ...field, commission: val } : field
        )
      );
    }
  };

  // save commission in database
  const handleBlur = async (id, val) => {
    if (val > 0) {
      handleUpdateFields(
        {
          commission: val,
        },
        id
      );
    }
  };

  const handleChange2 = (selected) => {
    setSelectedOptions(selected || []);
  };

  // Custom placeholder to show selected count
  const customPlaceholder = () => {
    const count = selectedOptions.length;
    if (count === 0) return "Select...";
    if (count === 1) return `${count} selected`;
    return `${count} selected`;
  };

  const handleViewPublic = (el) => {
    if (selectedOption.label === "Booking") {
      navigate(AppConstants.routes.tour.home, { state: { activityId: el.id } });
      // window.open('/target-path', '_blank', 'noopener,noreferrer');
    } else if (selectedOption.label === "Restaurant") {
      navigate(AppConstants.routes.restaurant.home, {
        state: { restaurantId: el.id },
      });
    } else if (selectedOption.label === "Nightlife") {
      navigate(AppConstants.routes.nightLife.home, {
        state: { nightId: el.id },
      });
    }
  };

  const handleEdit = (el) => {
    setActivityType(el?.company_type);
    setActivityID(el?.id);
    setShow(true);
    setIsNightlifeMenuAvailable(el?.is_menu_available);
  };

  const handleDelete = (id) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(id)}
        >
          Yes
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (id) => {
    let resp = {};
    if (selectedOption.label === "Booking") {
      resp = await authCall("deleteActivity", {
        bookingId: id,
        isSoft: true,
      });
    } else if (selectedOption.label === "Restaurant") {
      resp = await authCall("deleteRestaurant", {
        restaurantId: id,
        isSoft: true,
      });
    }
    if (resp?.type === "success") {
      closeToast();
      const updatedItems = tagList.filter((item) => item.id !== id);
      setTagsList(updatedItems);
      toast.success("Deleted successfully.");
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  const handleClose = () => setShow(false);

  const handleCostPerson = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateCostPerson, {
      state: { activityId: id },
    });
  };

  const handleCompanyDetails = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateCompanyDetails, {
      state: { activityId: id },
    });
  };

  const handleLocationDetails = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateLocationDetails, {
      state: { activityId: id },
    });
  };

  const handleStopDetails = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateStopDetails, {
      state: { activityId: id },
    });
  };

  const handleCompanyCategory = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateCompanyCategory, {
      state: { activityId: id },
    });
  };

  const handleCategory = (id) => {
    navigate(AppConstants.routes.admin.updateRestaurantCategory, {
      state: { restaurantId: id },
    });
  };

  const handleDetails = (id) => {
    navigate(AppConstants.routes.admin.updateRestaurantDetails, {
      state: { restaurantId: id },
    });
  };

  const handleMenu = (id) => {
    navigate(AppConstants.routes.admin.updateRestaurantMenu, {
      state: { restaurantId: id },
    });
  };

  const handleMenuSelect = async (restaurantId, menuType, menuId) => {
    setSelectedMenus((prevSelectedMenus) => {
      const updatedMenus = { ...prevSelectedMenus };
      updatedMenus[restaurantId][menuType] = menuId;
      return updatedMenus;
    });

    await authCall("createMenuFrontPage", {
      menuId: menuId,
      restaurantId: restaurantId,
      menuType: menuType,
    });
  };

  const CustomValueContainer = ({ children, ...props }) => {
    const count = props.getValue().length;
    return (
      <components.ValueContainer {...props}>
        {count > 0 ? `${count} selected` : "Select"}
      </components.ValueContainer>
    );
  };

  // Nightlife

  const handleNightCategory = (id) => {
    navigate(AppConstants.routes.admin.nightlifeUpdateCategory, {
      state: { nightlifeId: id },
    });
  };

  const handleNightEventDetails = (id) => {
    navigate(AppConstants.routes.admin.nightlifeUpdateVanueDetails, {
      state: { nightlifeId: id },
    });
  };

  const handleNightlifeMenu = (id) => {
    navigate(AppConstants.routes.admin.nightlifeUpdateMenu, {
      state: { nightlifeId: id },
    });
  };

  const handleNightlifeEvents = (id) => {
    setShowNightlifeEvent(true);
    setSelectedNightlifeId(id);
  };

  const closeNightlifeEvent = () => {
    setShowNightlifeEvent(false);
  };

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Tags</div>
                <div className="details-wrapper py-lg-4">
                  <Row className="align-items-center mb-4">
                    <Col xs={4}>
                      <span className="fs-sm">Filter by Company Type:</span>
                      <Select
                        className="text-body mt-1"
                        placeholder="Select"
                        value={selectedOption}
                        onChange={handleChange}
                        options={COMPANYTYPE}
                      />
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">Id:</th>
                          {selectedOption?.label === "Booking" && (
                            <th scope="col">Company Name:</th>
                          )}
                          {selectedOption?.label === "Restaurant" && (
                            <th scope="col">Restaurant Name:</th>
                          )}
                          {selectedOption?.label === "Nightlife" && (
                            <th scope="col">Venue Name:</th>
                          )}
                          <th scope="col">Contact:</th>
                          <th scope="col">Phone:</th>
                          <th scope="col">Image:</th>
                          {selectedOption?.label === "Booking" && (
                            <th scope="col">Commi.:</th>
                          )}
                          <th scope="col">Front Page:</th>
                          <th scope="col">Sub Page:</th>
                          <th scope="col">City Page:</th>
                          <th scope="col">Category:</th>
                          <th scope="col">Other Category:</th>
                          <th scope="col">Status:</th>
                          {selectedOption?.label === "Restaurant" && (
                            <th scope="col">Active Menus:</th>
                          )}
                          <th scope="col">Action:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (tagList.length > 0 ? (
                            tagList.map((el, index) => (
                              <tr className="custom-field-wrapper" key={index}>
                                <td scope="row">#{el.id}</td>
                                <td
                                  style={{
                                    color: `${
                                      selectedOption?.label === "Booking" &&
                                      !el?.stripe_account_id
                                        ? "red"
                                        : ""
                                    }`,
                                    maxWidth: 150,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  title={el.company_name}
                                >
                                  {el.company_name}
                                </td>
                                <td>{el.user_name}</td>
                                <td>{el.mobile_number}</td>
                                <td>
                                  <div className="d-flex gap-1 text-nowrap">
                                    <span>
                                      <button
                                        type="button"
                                        className="btn btn-link fs-16 text-blue border-0 p-0"
                                        onClick={() => handleShowModal(el)}
                                      >
                                        LINK
                                      </button>
                                    </span>
                                  </div>
                                </td>
                                {selectedOption?.label === "Booking" && (
                                  <td>
                                    <div className="input-wrapper-comm">
                                      <input
                                        type="text"
                                        className="form-control text-input-comm"
                                        value={el?.commission}
                                        onChange={(e) =>
                                          handleAddCommission(
                                            el.id,
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) =>
                                          handleBlur(el.id, e.target.value)
                                        }
                                      />
                                      <span className="postfix-comm">%</span>
                                    </div>
                                  </td>
                                )}
                                <td>
                                  <div className="field-cover">
                                    <select
                                      className={`form-control ${
                                        el.front_page_tag === "Yes"
                                          ? "text-white"
                                          : ""
                                      } form-select ${
                                        el.front_page_tag === "Yes"
                                          ? "bg-success"
                                          : ""
                                      }`}
                                      style={{ width: 85, paddingLeft: 6 }}
                                      value={el.front_page_tag}
                                      onChange={(e) =>
                                        handleChangeStatus(
                                          "frontPageTag",
                                          e.target.value,
                                          el.id
                                        )
                                      }
                                    >
                                      <option value="" hidden>
                                        Select
                                      </option>
                                      <option
                                        value="None"
                                        selected={
                                          el.front_page_tag === "None"
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        None
                                      </option>
                                      <option
                                        value="Yes"
                                        selected={
                                          el.front_page_tag === "Yes"
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        Yes
                                      </option>
                                      <option
                                        value="No"
                                        selected={
                                          el.front_page_tag === "No"
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        No
                                      </option>
                                    </select>
                                  </div>
                                </td>

                                <td>
                                  <div className="field-cover">
                                    {selectedOption.label === "Booking" ? (
                                      <Select
                                        className="text-body mt-1"
                                        options={subPageList}
                                        isMulti
                                        value={el.sku}
                                        onChange={(e) =>
                                          handleChangeSubPage(e, el.id)
                                        }
                                        placeholder="Select"
                                        components={{
                                          ValueContainer: CustomValueContainer,
                                        }}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        styles={customStyles}
                                      />
                                    ) : (
                                      <select
                                        className="form-control form-select"
                                        id="approvedStatus"
                                        style={{ width: 165, paddingLeft: 6 }}
                                        onChange={(e) =>
                                          handleChangeStatus(
                                            "subPageTag",
                                            e.target.value,
                                            el.id
                                          )
                                        }
                                      >
                                        <option value="" hidden>
                                          Select
                                        </option>
                                        <option value="none">None</option>
                                        {subPageList.map((e, index) => (
                                          <option
                                            value={e.label}
                                            key={index}
                                            selected={
                                              el.sub_page_tag === e.label
                                                ? "selected"
                                                : ""
                                            }
                                          >
                                            {e.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="field-cover">
                                    <select
                                      className="form-control form-select"
                                      id="approvedStatus"
                                      style={{ width: 145, paddingLeft: 6 }}
                                      onChange={(e) =>
                                        handleChangeStatus(
                                          "cityPageTag",
                                          e.target.value,
                                          el.id
                                        )
                                      }
                                    >
                                      <option value="" hidden>
                                        Select
                                      </option>
                                      <option value="none">None</option>
                                      {cityPageList.map((ell, index) => {
                                        return (
                                          <option
                                            value={ell.label}
                                            key={index}
                                            selected={
                                              el.city_page_tag === ell.label
                                                ? "selected"
                                                : ""
                                            }
                                          >
                                            {ell.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </td>
                                <td>{el.category}</td>
                                <td>{el.other_category}</td>
                                <td>
                                  <div className="field-cover">
                                    <select
                                      className={`form-control text-white form-select ${
                                        el.status === "0"
                                          ? "bg-dark"
                                          : el.status === "1"
                                          ? "bg-success"
                                          : "bg-danger"
                                      }`}
                                      id="approvedStatus"
                                      style={{ width: 120, paddingLeft: 6 }}
                                      value={el.status}
                                      onChange={(e) =>
                                        handleChangeStatusValue(
                                          el.id,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="" hidden>
                                        Select
                                      </option>
                                      <option
                                        value="0"
                                        selected={
                                          el.status === "0" ? "selected" : ""
                                        }
                                      >
                                        None
                                      </option>
                                      <option
                                        value="1"
                                        selected={
                                          el.status === "1" ? "selected" : ""
                                        }
                                      >
                                        Approve
                                      </option>
                                      <option
                                        value="2"
                                        selected={
                                          el.status === "2" ? "selected" : ""
                                        }
                                      >
                                        Reject
                                      </option>
                                    </select>
                                  </div>
                                </td>
                                {selectedOption.label === "Restaurant" && (
                                  <td>
                                    <div className="form-group align-items-center active-menu">
                                      {/* Breakfast Menu */}
                                      {el.open_breakfast === "1" &&
                                        el?.menus?.Breakfast && (
                                          <>
                                            <span
                                              style={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                              className="text-custom-primary"
                                            >
                                              Breakfast Menu
                                            </span>
                                            <ul className="no-bullets">
                                              {el?.menus?.Breakfast &&
                                                el?.menus?.Breakfast.map(
                                                  (menu) => (
                                                    <li
                                                      key={menu.id}
                                                      className="d-flex gap-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        className="custom-radio1 m-0 companyType cursor-pointer"
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                          minWidth: 15,
                                                          minHeight: 15,
                                                        }}
                                                        name={`breakfast-${el.id}`}
                                                        id={`breakfast-${menu.id}`}
                                                        checked={
                                                          selectedMenus[el.id]
                                                            ?.breakfast ===
                                                          menu.id
                                                        }
                                                        onChange={() =>
                                                          handleMenuSelect(
                                                            el.id,
                                                            "breakfast",
                                                            menu.id
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="fs-12"
                                                        htmlFor={`breakfast-${menu.id}`}
                                                      >
                                                        {menu?.menu_name}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </>
                                        )}
                                      {/* Lunch Menu */}
                                      {el.open_lunch === "1" &&
                                        el?.menus?.Lunch && (
                                          <>
                                            <span
                                              style={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                              className="text-custom-primary"
                                            >
                                              Lunch Menu
                                            </span>
                                            <ul className="no-bullets">
                                              {el?.menus?.Lunch &&
                                                el?.menus?.Lunch.map((menu) => (
                                                  <li
                                                    key={menu.id}
                                                    className="d-flex gap-1"
                                                  >
                                                    <input
                                                      type="radio"
                                                      className="custom-radio1 m-0 companyType cursor-pointer"
                                                      style={{
                                                        width: 15,
                                                        height: 15,
                                                        minWidth: 15,
                                                        minHeight: 15,
                                                      }}
                                                      name={`lunch-${el.id}`}
                                                      id={`lunch-${menu.id}`}
                                                      checked={
                                                        selectedMenus[el.id]
                                                          ?.lunch === menu.id
                                                      }
                                                      onChange={() =>
                                                        handleMenuSelect(
                                                          el.id,
                                                          "lunch",
                                                          menu.id
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="fs-12"
                                                      htmlFor={`lunch-${menu.id}`}
                                                    >
                                                      {menu?.menu_name}
                                                    </label>
                                                  </li>
                                                ))}
                                            </ul>
                                          </>
                                        )}
                                      {/* Dinner Menu */}
                                      {el.open_dinner === "1" &&
                                        el?.menus?.Dinner && (
                                          <>
                                            <span
                                              style={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                              className="text-custom-primary"
                                            >
                                              Dinner Menu
                                            </span>

                                            <ul className="no-bullets">
                                              {el?.menus?.Dinner &&
                                                el?.menus?.Dinner.map(
                                                  (menu) => (
                                                    <li
                                                      key={menu.id}
                                                      className="d-flex gap-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        className="custom-radio1 m-0 companyType cursor-pointer"
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                          minWidth: 15,
                                                          minHeight: 15,
                                                        }}
                                                        name={`dinner-${el.id}`}
                                                        id={`dinner-${el.id}`}
                                                        checked={
                                                          selectedMenus[el.id]
                                                            ?.dinner === menu.id
                                                        }
                                                        onChange={() =>
                                                          handleMenuSelect(
                                                            el.id,
                                                            "dinner",
                                                            menu.id
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="fs-12"
                                                        htmlFor={`dinner-${menu.id}`}
                                                      >
                                                        {menu?.menu_name}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </>
                                        )}
                                      {/* All day Menu */}
                                      {el.open_allday === "1" &&
                                        el?.menus?.all_day_menu && (
                                          <>
                                            <span
                                              style={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                              className="text-custom-primary"
                                            >
                                              All Day Menu
                                            </span>
                                            <ul className="no-bullets">
                                              {el?.menus?.all_day_menu &&
                                                el?.menus?.all_day_menu.map(
                                                  (menu, index) => (
                                                    <li
                                                      key={index}
                                                      className="d-flex gap-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        className="custom-radio1 m-0 companyType cursor-pointer"
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                          minWidth: 15,
                                                          minHeight: 15,
                                                        }}
                                                        name={`all_day_menu-${el.id}`}
                                                        id={`all_day_menu-${menu.id}`}
                                                        checked={
                                                          selectedMenus[el.id]
                                                            ?.all_day_menu ===
                                                          menu.id
                                                        }
                                                        onChange={() =>
                                                          handleMenuSelect(
                                                            el.id,
                                                            "all_day_menu",
                                                            menu.id
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="fs-12"
                                                        htmlFor={`all_day_menu-${menu.id}`}
                                                      >
                                                        {menu?.menu_name}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </>
                                        )}
                                    </div>
                                  </td>
                                )}
                                <td>
                                  <div className="icon-container">
                                    <PencilSquare
                                      onClick={() => handleEdit(el)}
                                      className="cursor-pointer"
                                      title="Click to edit"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <EyeFill
                                      onClick={() => handleViewPublic(el)}
                                      title="Click to view details"
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <Trash
                                      onClick={() => handleDelete(el.id)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageGridModal
        images={images?.banners || []}
        bannerTag={images?.banner_tags || ""}
        show={showModal}
        handleClose={handleCloseModal}
        handlePrimaryChange={handleMakePrimary}
      />
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        size={selectedOption.label === "Booking" ? "lg" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            Update {selectedOption.label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-field-wrapper p-3">
          <div className="table-responsive common-info-card border-0">
            <table className="table table-borderless table-custom m-0">
              <thead>
                {selectedOption.label === "Booking" && (
                  <tr>
                    <th scope="col">Cost Per Person:</th>
                    <th scope="col">Category Selection:</th>
                    <th scope="col">Company Details:</th>
                    <th scope="col">Stop Details:</th>
                    <th scope="col">Location Details:</th>
                  </tr>
                )}

                {selectedOption.label === "Restaurant" && (
                  <tr>
                    <th scope="col">Category:</th>
                    <th scope="col">Details:</th>
                    <th scope="col">Menu:</th>
                  </tr>
                )}

                {selectedOption.label === "Nightlife" && (
                  <tr>
                    <th scope="col">Category:</th>
                    <th scope="col">Venue Details:</th>
                    <th scope="col">Menu:</th>
                    <th scope="col">Events:</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {selectedOption.label === "Booking" && (
                  <tr>
                    <td className="text-blue">
                      <span
                        onClick={() => handleCostPerson(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleCompanyCategory(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleCompanyDetails(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      {activityType === "Tour" && (
                        <span
                          onClick={() => handleStopDetails(activityID)}
                          className="cursor-pointer"
                        >
                          <PencilSquare /> Edit
                        </span>
                      )}
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleLocationDetails(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                  </tr>
                )}
                {selectedOption.label === "Restaurant" && (
                  <tr>
                    <td className="text-blue">
                      <span
                        onClick={() => handleCategory(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleDetails(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleMenu(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                  </tr>
                )}
                {selectedOption.label === "Nightlife" && (
                  <tr>
                    <td className="text-blue">
                      <span
                        onClick={() => handleNightCategory(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleNightEventDetails(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      {isNightlifeMenuAvailable === "Yes" && (
                        <span
                          onClick={() => handleNightlifeMenu(activityID)}
                          className="cursor-pointer"
                        >
                          <PencilSquare /> Edit
                        </span>
                      )}
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleNightlifeEvents(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <NightlifeEventListPopup
        show={showNightlifeEvent}
        handleClose={closeNightlifeEvent}
        selectedNightlifeId={selectedNightlifeId}
      />
    </>
  );
}

export default TagsList;
