import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TestimonialsComponent from "./TestimonialsComponent";
import { authCall } from "../services";
import { generateSku } from "./util/utils";
import BannerComponent from "./BannerComponent";
import BannerSubComponent from "./BannerSubComponent";
import AppConstants from "../appConstants";
import authService from "../services/authServices";
import StarRating from "./common/StarRating";

function SubPageListComponent() {
  const location = useLocation();
  const { title, banners } = location.state || {};
  const navigate = useNavigate();
  const { pathname } = location;
  const [details, setDetails] = useState([]);
  const [exclSkelArray] = useState(new Array(4).fill());
  const [loading, setLoading] = useState(false);
  const [bannerTitle, setBannerTitle] = useState({
    subTitle: "Experience it all",
    buttonTitle: "Search",
    buttonLink: AppConstants.routes.bookingSearch,
  });

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const type = authService.getCompanyType(pathname); // helper function
      if (type === "Activity") {
        setBannerTitle({
          ...bannerTitle,
          subTitle: "Experience it all",
          buttonLink: AppConstants.routes.bookingSearch,
        });
      } else if (type === "Restaurant") {
        setBannerTitle({
          ...bannerTitle,
          subTitle: "Tast it all",
          buttonLink: AppConstants.routes.dinningSearch,
        });
      } else if (type === "Nightlife") {
        setBannerTitle({
          ...bannerTitle,
          subTitle: "See it all",
          buttonLink: AppConstants.routes.nightlifeSearch,
        });
      }
      const path = pathname.split("/").pop();
      const resp = await authCall("getSubPageData", {
        type,
        tags: path,
      });
      if (resp?.type === "success") {
        setDetails(resp?.payload || []);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    getData();
  }, [pathname]);

  const handleClickBooking = (item) => {
    const type = authService.getCompanyType(pathname); // helper function
    if (type === "Activity") {
      const name = generateSku(item.company_name);
      navigate(`/company/${name}`, {
        state: { companyName: item.company_name },
      });
    } else if (type === "Restaurant") {
      const name = generateSku(item.name);
      navigate(`/dining/${name}`, { state: { restaurantId: item.id } });
    } else if (type === "Nightlife") {
      const name = generateSku(item.name);
      navigate(`/night-life/${name}`, { state: { nightId: item.id } });
    }
  };

  return (
    <>
      {/* BANNER */}
      <BannerComponent
        banner={banners}
        title={title}
        subTitle={bannerTitle.subTitle}
        // button={{
        //   title: bannerTitle.buttonTitle,
        //   link: bannerTitle.buttonLink,
        // }}
        button={{ title: "Back" }}
      />

      {/* BEST PRICE GUARANTEED */}
      <BannerSubComponent />

      <section className="top-experiences result-listing py-3">
        <div className="container">
          <div className="row util-carousell">
            {loading &&
              exclSkelArray.map(() => (
                <div className="col-xs-6 col-md-4 col-lg-3 mb-4 item">
                  <div className="join-blocks">
                    <Skeleton
                      className="join-blocks-img"
                      style={{ borderRadius: 13 }}
                    />
                    <Skeleton count={1} width="80%" />
                    <Skeleton count={1} className="mt-3" width="60%" />
                    <Skeleton count={1} className="mt-3" width="30%" />
                  </div>
                </div>
              ))}

            {!loading &&
              details.map((el, index) => {
                return (
                  <div
                    key={index}
                    className="col-xs-6 col-md-4 col-lg-3 mb-4 item"
                  >
                    <div className="join-blocks">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.company_name}
                          onClick={() => handleClickBooking(el)}
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking(el)}
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          margin: "3px 0 0",
                        }}
                      >
                        {el.company_name}
                      </h4>
                      {el.name && (
                        <h4
                          className="cursor-pointer"
                          onClick={() => handleClickBooking(el)}
                        >
                          {el.name}
                        </h4>
                      )}
                      <StarRating rating={el?.total_ratings} />
                      {el?.reviews && (
                        <span className="price">
                          <ins>Based on {el?.reviews} Reviews</ins>
                        </span>
                      )}
                      {el?.display_price?.price && (
                        <span className="price">
                          {el?.display_price?.advertisePrice && (
                            <>
                              from{" "}
                              <del>${el?.display_price?.advertisePrice}</del>
                            </>
                          )}
                          <ins>${el?.display_price?.price}</ins>
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <section className="top-experiences testimonials mt-5 py-5">
        <TestimonialsComponent />
      </section>
    </>
  );
}

export default SubPageListComponent;
