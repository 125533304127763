import React, { useState } from "react";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { QuestionCircle } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import ActivitySidebar from "../../layouts/TourSidebar";
import SearchIcon from "./../../../assets/images/admin/search.svg";
import AppConstants from "../../../appConstants";
import VideoTutorialPopUp from "../../admin/VideoTutorialPopUp";
import { authCall } from "../../../services";

const RezdyIntegrationVideo = AppConstants.tutorial.rezdyIntegrationVideo;

function FareHarborIntegration({
  handleSubmit,
  loadingAdd,
  stepValue,
  setStep,
}) {
  const companyDetails = useSelector((state) => state.companyDetails);
  const [productCode, setProductCode] = useState("");
  const [isVariation] = useState(companyDetails?.variation_parent_id || false);
  const [variationName, setVariationName] = useState("");
  const [activityDetails, setActivityDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  const handleSearchShortName = async () => {
    toast.dismiss();
    if (isVariation && variationName === "") {
      toast.error("Please enter variation name.");
    } else if (productCode === "") {
      toast.error("Please enter company shortname.");
    } else {
      setLoading(true);
      const resp = await authCall("fareharborItems", {
        shortName: productCode,
      });
      if (resp?.type === "success") {
        setActivityDetails(resp?.payload);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Product company shortname not valid.");
      }
    }
  };

  const handleClickProductCode = (item) => {
    const data = {
      profileLavel: stepValue,
      activityCode: productCode,
      activityName: item?.name,
      overview: DOMPurify.sanitize(item?.description),
      priceOptions: item?.customer_prototypes,
      advertisedPrice: null,
      // companyName: productCode,
      cancellationPolicy: item?.cancellation_policy,
      fareharborPKId: item?.pk,
      whatToBring: item?.booking_notes,
      // address: {
      //   streetName: item?.primary_location?.address?.street || "",
      //   city: item?.primary_location?.address?.city || "",
      //   state: { value: "Queensland", label: "Queensland" },
      //   zipCode: "4217",
      // },
      variationName: variationName,
    };
    handleSubmit(data, stepValue);
  };

  const handleClickTutorial = () => {
    setVideoURL(RezdyIntegrationVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="rezdy-integration-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <ActivitySidebar setStep={setStep} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>FareHarbor Integration</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>

                <div className="custom-field-wrapperr p-3 pb-4">
                  <div className="fs-18 fw-medium mb-4">
                    This ensures all bookings appear on your FareHarbor calendar
                  </div>
                  <div className="mb-4">
                    {isVariation && (
                      <div className="form-group mb-3">
                        <label className="form-label fs-18 text-white me-3">
                          Please enter your variation name:
                        </label>
                        <div className="d-flex flex-wrap align-items-center gap-3">
                          <div
                            className="searchfield-wrapper"
                            style={{ maxWidth: "400px", width: "100%" }}
                          >
                            <input
                              type="text"
                              className="form-control rounded-pill py-2 h-auto pe-5"
                              autoComplete="off"
                              value={variationName}
                              onChange={(e) => {
                                if (e.target.value.length < 36) {
                                  setVariationName(e.target.value);
                                }
                              }}
                            />
                            <div className="main-heading2 fs-12 fw-light text-end mt-1">
                              <span id="charCount">{variationName.length}</span>
                              /35
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group mb-0">
                      <label className="form-label fs-18 text-white me-3">
                        Please enter your company shortname:
                      </label>
                      <div className="d-flex flex-wrap align-items-center gap-3">
                        <div
                          className="searchfield-wrapper"
                          style={{ maxWidth: "400px", width: "100%" }}
                        >
                          <input
                            type="search"
                            className="form-control rounded-pill py-2 h-auto pe-5"
                            autoComplete="off"
                            onChange={(e) => setProductCode(e.target.value)}
                          />
                          <img
                            src={SearchIcon}
                            className="img-responsive"
                            alt="Search Icon"
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-custom"
                          onClick={handleSearchShortName}
                          disabled={loading || false}
                        >
                          {loading ? "Searching..." : "Search"}
                        </button>
                      </div>
                      {/* <Link
                        className="fs-12"
                        to="https://auth.rezdy.com/login"
                        target="_blank"
                      >
                        Click here to open your Rezdy account and retrieve your
                        product code
                      </Link> */}
                    </div>
                  </div>
                  {activityDetails.length > 0 && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">Code</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activityDetails.map((el) => (
                            <tr>
                              <td>
                                <div className="company-name">{el?.name}</div>
                              </td>
                              <td>
                                {el?.customer_prototypes.map((price) => (
                                  <div className="price-cover d-flex gap-3">
                                    <div className="type p-0">
                                      {price?.display_name}
                                    </div>
                                    <div className="amount">
                                      ${price?.total}
                                    </div>
                                  </div>
                                ))}
                              </td>
                              <td>{el?.pk}</td>
                              <td>
                                <Button
                                  className="btn btn-custom btn-custom-sm fs-16"
                                  onClick={() => handleClickProductCode(el)}
                                  disabled={loadingAdd || false}
                                >
                                  {loadingAdd ? "Adding..." : "Add"}
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default FareHarborIntegration;
