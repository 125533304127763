import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Row, Col, Button } from "react-bootstrap";
import { EyeFill, Plus, PencilSquare, Trash } from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";
import { handleNumericInput } from "../../util/utils";
import NightlifeEventListPopup from "./NightlifeEventListPopup";

const USERTYPE = [
  { value: 1, label: "Activity" },
  { value: 2, label: "Restaurant" },
  { value: 3, label: "Bar" },
];

const SHOWCOMMISION = ["Activity", "Licensee", "Promoter"];
const USERS = ["Licensee", "Promoter", "Advertiser", "Designer"];

function EditListing() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { type } = location?.state || {};
  const [machinesList, setMachinesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [loadingStatus, setLoadingStatus] = useState("");
  const [filterUserList, setFilterUserList] = useState([]);
  const [userList, serList] = useState([]);
  const [show, setShow] = useState(false);
  const [activityID, setActivityID] = useState("");
  const [activityType, setActivityType] = useState("");
  const [isNightlifeMenuAvailable, setIsNightlifeMenuAvailable] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: type ? type : "Activity",
    label: type ? type : "Activity",
  });

  const [selectedMenus, setSelectedMenus] = useState({});
  const [showNightlifeEvent, setShowNightlifeEvent] = useState(false);
  const [selectedNightlifeId, setSelectedNightlifeId] = useState("");

  const getUserList = async (type, status = false) => {
    if (!status) {
      setLoading(true);
    }

    const resp = await authCall("getEditListing", {
      userType: type,
      isAdmin: true,
    });
    if (resp?.type === "success") {
      setFilterUserList(resp?.payload);
      setLoading(false);

      if (type === "Restaurant") {
        // Initialize selected menus
        const menulist = resp?.payload;
        const initialSelection2 = menulist.reduce((acc, restaurant) => {
          acc[restaurant.id] = {
            breakfast:
              restaurant?.menus?.Breakfast?.find(
                (menu) => menu.front_page_tag > 0
              )?.id || null,
            lunch:
              restaurant?.menus?.Lunch?.find((menu) => menu.front_page_tag > 0)
                ?.id || null,
            dinner:
              restaurant?.menus?.Dinner?.find((menu) => menu.front_page_tag > 0)
                ?.id || null,
            all_day_menu:
              restaurant?.menus?.all_day_menu?.find(
                (menu) => menu.front_page_tag > 0
              )?.id || null,
          };
          return acc;
        }, {});
        setSelectedMenus(initialSelection2);
      }
    }
  };

  useEffect(() => {
    let type = "";
    if (pathname === AppConstants.routes.admin.activityList) {
      type = "Activity";
    } else if (pathname === AppConstants.routes.admin.restaurantList) {
      type = "Restaurant";
    } else if (pathname === AppConstants.routes.admin.nightLifeList) {
      type = "Nightlife";
    } else {
      navigate(AppConstants.routes.admin.dashboard);
    }
    setTypeName(type);
    getUserList(type);
  }, [pathname]);

  const handleEdit = (el) => {
    setActivityType(el?.company_type);
    setActivityID(el?.id);
    setShow(true);
    setIsNightlifeMenuAvailable(el?.is_menu_available);
  };

  // add commisions to bookign and users
  const handleAddCommission = (id, val) => {
    const inputValue = handleNumericInput(val);
    if (inputValue !== null && inputValue.length < 4) {
      setFilterUserList(
        filterUserList.map((field) =>
          field.id === id ? { ...field, commission: val } : field
        )
      );
    }
  };

  const handleUpdateFields = async (params, id) => {
    if (typeName === "Restaurant") {
      await webServices.createRestaurant({
        ...params,
        restaurantId: id,
      });
    } else if (typeName === "Nightlife") {
      await webServices.createNightLife({
        ...params,
        nightLifeId: id,
      });
    } else {
      await webServices.createTour({
        ...params,
        bookingId: id,
      });
    }
  };

  // save commission in database
  const handleBlur = async (id, val) => {
    if (val > 0) {
      handleUpdateFields(
        {
          commission: val,
        },
        id
      );
    }
  };

  const handleChangeStatusValue = (id, newStatus) => {
    const updatedItems = filterUserList
      .map((item) => (item.id === id ? { ...item, status: newStatus } : item))
      .sort((a, b) => a.status.localeCompare(b.status));
    setFilterUserList(updatedItems);
    handleUpdateFields(
      {
        status: newStatus,
      },
      id
    );
  };

  const handleViewPublic = (el) => {
    if (typeName === "Activity") {
      navigate(AppConstants.routes.tour.home, { state: { activityId: el.id } });
      // window.open('/target-path', '_blank', 'noopener,noreferrer');
    } else if (typeName === "Restaurant") {
      navigate(AppConstants.routes.restaurant.home, {
        state: { restaurantId: el.id },
      });
    } else if (typeName === "Nightlife") {
      navigate(AppConstants.routes.nightLife.home, {
        state: { nightId: el.id },
      });
    }
    // console.log("el", typeName, el);
    // navigate(AppConstants.routes.admin.userUpdate, { state: { details } });
  };

  const handleDelete = (id) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(id)}
        >
          Yes
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (id) => {
    let resp = {};
    if (typeName === "Activity") {
      resp = await authCall("deleteActivity", {
        bookingId: id,
        isSoft: true,
      });
    } else if (typeName === "Restaurant") {
      resp = await authCall("deleteRestaurant", {
        restaurantId: id,
        isSoft: true,
      });
    } else if (typeName === "Nightlife") {
      resp = await authCall("deleteNightlife", {
        nightlifeId: id,
        isSoft: true,
      });
    }
    if (resp?.type === "success") {
      closeToast();
      const updatedItems = filterUserList.filter((item) => item.id !== id);
      setFilterUserList(updatedItems);
      toast.success("Deleted successfully.");
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  const handleClose = () => setShow(false);

  const handleCostPerson = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateCostPerson, {
      state: { activityId: id },
    });
  };

  const handleCompanyDetails = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateCompanyDetails, {
      state: { activityId: id },
    });
  };

  const handleLocationDetails = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateLocationDetails, {
      state: { activityId: id },
    });
  };

  const handleStopDetails = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateStopDetails, {
      state: { activityId: id },
    });
  };

  const handleCompanyCategory = (id) => {
    navigate(AppConstants.routes.admin.adminUpdateCompanyCategory, {
      state: { activityId: id },
    });
  };

  const handleCategory = (id) => {
    navigate(AppConstants.routes.admin.updateRestaurantCategory, {
      state: { restaurantId: id },
    });
  };

  const handleDetails = (id) => {
    navigate(AppConstants.routes.admin.updateRestaurantDetails, {
      state: { restaurantId: id },
    });
  };

  const handleMenu = (id) => {
    navigate(AppConstants.routes.admin.updateRestaurantMenu, {
      state: { restaurantId: id },
    });
  };

  const handleMenuSelect = async (restaurantId, menuType, menuId) => {
    setSelectedMenus((prevSelectedMenus) => {
      const updatedMenus = { ...prevSelectedMenus };
      updatedMenus[restaurantId][menuType] = menuId;
      return updatedMenus;
    });

    await authCall("createMenuFrontPage", {
      menuId: menuId,
      restaurantId: restaurantId,
      menuType: menuType,
    });
  };

  // Nightlife
  const handleNightCategory = (id) => {
    navigate(AppConstants.routes.admin.nightlifeUpdateCategory, {
      state: { nightlifeId: id },
    });
  };

  const handleNightEventDetails = (id) => {
    navigate(AppConstants.routes.admin.nightlifeUpdateVanueDetails, {
      state: { nightlifeId: id },
    });
  };

  const handleNightlifeMenu = (id) => {
    navigate(AppConstants.routes.admin.nightlifeUpdateMenu, {
      state: { nightlifeId: id },
    });
  };

  const handleNightlifeEvents = (id) => {
    setShowNightlifeEvent(true);
    setSelectedNightlifeId(id);
  };

  // nightlife eventlife events
  const closeNightlifeEvent = () => {
    setShowNightlifeEvent(false);
  }

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">{typeName} Listing</div>
                <div className="details-wrapper py-lg-4">
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">Id:</th>
                          {typeName === "Activity" && (
                            <th scope="col">Activity Name:</th>
                          )}
                          {typeName === "Nightlife" && (
                            <th scope="col">Venue Name:</th>
                          )}
                          {typeName === "Restaurant" && (
                            <th scope="col">Restaurant Name:</th>
                          )}
                          <th scope="col">Contact:</th>
                          <th scope="col">Phone:</th>
                          {typeName === "Activity" && (
                            <th scope="col">Commi.:</th>
                          )}
                          <th scope="col">Status:</th>
                          {typeName === "Restaurant" && (
                            <th scope="col">Active Menus:</th>
                          )}

                          <th scope="col">Action:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (filterUserList.length > 0 ? (
                            filterUserList.map((el, index) => (
                              <tr key={index}>
                                <td scope="row">#{el.id}</td>
                                <td
                                  style={{
                                    color: `${
                                      typeName === "Activity" &&
                                      !el?.stripe_account_id
                                        ? "red"
                                        : ""
                                    }`,
                                  }}
                                >
                                  {el.company_name}
                                </td>
                                <td>{el.user_name}</td>
                                <td>{el.mobile_number}</td>
                                {typeName === "Activity" && (
                                  <td>
                                    <div className="input-wrapper-comm">
                                      <input
                                        type="text"
                                        className="form-control text-input-comm"
                                        value={el?.commission}
                                        onChange={(e) =>
                                          handleAddCommission(
                                            el.id,
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) =>
                                          handleBlur(el.id, e.target.value)
                                        }
                                      />
                                      <span className="postfix-comm">%</span>
                                    </div>
                                  </td>
                                )}
                                <td>
                                  <div className="field-cover">
                                    <select
                                      className={`form-control text-white form-select ${
                                        el.status === "0"
                                          ? "bg-dark"
                                          : el.status === "1"
                                          ? "bg-success"
                                          : "bg-danger"
                                      }`}
                                      id="approvedStatus"
                                      style={{ width: 120, paddingLeft: 6 }}
                                      value={el.status}
                                      onChange={(e) =>
                                        handleChangeStatusValue(
                                          el.id,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="" hidden>
                                        Select
                                      </option>
                                      <option
                                        value="0"
                                        selected={
                                          el.status === "0" ? "selected" : ""
                                        }
                                      >
                                        None
                                      </option>
                                      <option
                                        value="1"
                                        selected={
                                          el.status === "1" ? "selected" : ""
                                        }
                                      >
                                        Approve
                                      </option>
                                      <option
                                        value="2"
                                        selected={
                                          el.status === "2" ? "selected" : ""
                                        }
                                      >
                                        Reject
                                      </option>
                                    </select>
                                  </div>
                                </td>
                                {typeName === "Restaurant" && (
                                  <td>
                                    <div className="form-group align-items-center active-menu">
                                      {/* Breakfast Menu */}
                                      {el.open_breakfast === "1" &&
                                        el?.menus?.Breakfast && (
                                          <>
                                            <span
                                              style={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                              className="text-custom-primary"
                                            >
                                              Breakfast Menu
                                            </span>
                                            <ul className="no-bullets">
                                              {el?.menus?.Breakfast &&
                                                el?.menus?.Breakfast.map(
                                                  (menu) => (
                                                    <li
                                                      key={menu.id}
                                                      className="d-flex gap-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        className="custom-radio1 m-0 companyType cursor-pointer"
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                          minWidth: 15,
                                                          minHeight: 15,
                                                        }}
                                                        name={`breakfast-${el.id}`}
                                                        id={`breakfast-${menu.id}`}
                                                        checked={
                                                          selectedMenus[el.id]
                                                            ?.breakfast ===
                                                          menu.id
                                                        }
                                                        onChange={() =>
                                                          handleMenuSelect(
                                                            el.id,
                                                            "breakfast",
                                                            menu.id
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="fs-12"
                                                        htmlFor={`breakfast-${menu.id}`}
                                                      >
                                                        {menu?.menu_name}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </>
                                        )}
                                      {/* Lunch Menu */}
                                      {el.open_lunch === "1" &&
                                        el?.menus?.Lunch && (
                                          <>
                                            <span
                                              style={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                              className="text-custom-primary"
                                            >
                                              Lunch Menu
                                            </span>
                                            <ul className="no-bullets">
                                              {el?.menus?.Lunch &&
                                                el?.menus?.Lunch.map((menu) => (
                                                  <li
                                                    key={menu.id}
                                                    className="d-flex gap-1"
                                                  >
                                                    <input
                                                      type="radio"
                                                      className="custom-radio1 m-0 companyType cursor-pointer"
                                                      style={{
                                                        width: 15,
                                                        height: 15,
                                                        minWidth: 15,
                                                        minHeight: 15,
                                                      }}
                                                      name={`lunch-${el.id}`}
                                                      id={`lunch-${menu.id}`}
                                                      checked={
                                                        selectedMenus[el.id]
                                                          ?.lunch === menu.id
                                                      }
                                                      onChange={() =>
                                                        handleMenuSelect(
                                                          el.id,
                                                          "lunch",
                                                          menu.id
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="fs-12"
                                                      htmlFor={`lunch-${menu.id}`}
                                                    >
                                                      {menu?.menu_name}
                                                    </label>
                                                  </li>
                                                ))}
                                            </ul>
                                          </>
                                        )}
                                      {/* Dinner Menu */}
                                      {el.open_dinner === "1" &&
                                        el?.menus?.Dinner && (
                                          <>
                                            <span
                                              style={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                              className="text-custom-primary"
                                            >
                                              Dinner Menu
                                            </span>

                                            <ul className="no-bullets">
                                              {el?.menus?.Dinner &&
                                                el?.menus?.Dinner.map(
                                                  (menu) => (
                                                    <li
                                                      key={menu.id}
                                                      className="d-flex gap-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        className="custom-radio1 m-0 companyType cursor-pointer"
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                          minWidth: 15,
                                                          minHeight: 15,
                                                        }}
                                                        name={`dinner-${el.id}`}
                                                        id={`dinner-${el.id}`}
                                                        checked={
                                                          selectedMenus[el.id]
                                                            ?.dinner === menu.id
                                                        }
                                                        onChange={() =>
                                                          handleMenuSelect(
                                                            el.id,
                                                            "dinner",
                                                            menu.id
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="fs-12"
                                                        htmlFor={`dinner-${menu.id}`}
                                                      >
                                                        {menu?.menu_name}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </>
                                        )}
                                      {/* All day Menu */}
                                      {el.open_allday === "1" &&
                                        el?.menus?.all_day_menu && (
                                          <>
                                            <span
                                              style={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                              className="text-custom-primary"
                                            >
                                              All Day Menu
                                            </span>
                                            <ul className="no-bullets">
                                              {el?.menus?.all_day_menu &&
                                                el?.menus?.all_day_menu.map(
                                                  (menu, index) => (
                                                    <li
                                                      key={index}
                                                      className="d-flex gap-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        className="custom-radio1 m-0 companyType cursor-pointer"
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                          minWidth: 15,
                                                          minHeight: 15,
                                                        }}
                                                        name={`all_day_menu-${el.id}`}
                                                        id={`all_day_menu-${menu.id}`}
                                                        checked={
                                                          selectedMenus[el.id]
                                                            ?.all_day_menu ===
                                                          menu.id
                                                        }
                                                        onChange={() =>
                                                          handleMenuSelect(
                                                            el.id,
                                                            "all_day_menu",
                                                            menu.id
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="fs-12"
                                                        htmlFor={`all_day_menu-${menu.id}`}
                                                      >
                                                        {menu?.menu_name}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </>
                                        )}
                                    </div>
                                  </td>
                                )}
                                <td>
                                  <div className="icon-container">
                                    <PencilSquare
                                      onClick={() => handleEdit(el)}
                                      className="cursor-pointer"
                                      title="Click to edit"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <EyeFill
                                      onClick={() => handleViewPublic(el)}
                                      title="Click to view details"
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <Trash
                                      onClick={() => handleDelete(el.id)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        size={typeName === "Activity" ? "lg" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Update {typeName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-field-wrapper p-3">
          <div className="table-responsive common-info-card border-0">
            <table className="table table-borderless table-custom m-0">
              <thead>
                {typeName === "Activity" && (
                  <tr>
                    <th scope="col">Cost Per Person:</th>
                    <th scope="col">Category Selection:</th>
                    <th scope="col">Company Details:</th>
                    <th scope="col">Stop Details:</th>
                    <th scope="col">Location Details:</th>
                  </tr>
                )}

                {typeName === "Restaurant" && (
                  <tr>
                    <th scope="col">Category:</th>
                    <th scope="col">Details:</th>
                    <th scope="col">Menu:</th>
                  </tr>
                )}

                {typeName === "Nightlife" && (
                  <tr>
                    <th scope="col">Category:</th>
                    <th scope="col">Venue Details:</th>
                    <th scope="col">Menu:</th>
                    <th scope="col">Events:</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {typeName === "Activity" && (
                  <tr>
                    <td className="text-blue">
                      <span
                        onClick={() => handleCostPerson(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleCompanyCategory(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleCompanyDetails(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      {activityType === "Tour" && (
                        <span
                          onClick={() => handleStopDetails(activityID)}
                          className="cursor-pointer"
                        >
                          <PencilSquare /> Edit
                        </span>
                      )}
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleLocationDetails(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                  </tr>
                )}

                {typeName === "Restaurant" && (
                  <tr>
                    <td className="text-blue">
                      <span
                        onClick={() => handleCategory(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleDetails(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleMenu(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                  </tr>
                )}

                {typeName === "Nightlife" && (
                  <tr>
                    <td className="text-blue">
                      <span
                        onClick={() => handleNightCategory(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleNightEventDetails(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                    <td className="text-blue">
                      {isNightlifeMenuAvailable === "Yes" && (
                        <span
                          onClick={() => handleNightlifeMenu(activityID)}
                          className="cursor-pointer"
                        >
                          <PencilSquare /> Edit
                        </span>
                      )}
                    </td>
                    <td className="text-blue">
                      <span
                        onClick={() => handleNightlifeEvents(activityID)}
                        className="cursor-pointer"
                      >
                        <PencilSquare /> Edit
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <NightlifeEventListPopup show={showNightlifeEvent} handleClose={closeNightlifeEvent} selectedNightlifeId={selectedNightlifeId}/>
    </>
  );
} 

export default EditListing;
