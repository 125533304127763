import React, { useState } from "react";
import { useSelector } from "react-redux";
import ActivitiesDashboard from "./activities/ActivitiesDashboard";
import NightLifeDashboard from "./nightLife/NightLifeDashboard";
import RestaurantDashboard from "./restaurant/RestaurantDashboard";

const DashboardAction = () => {
  const { current } = useSelector((state) => state.user);
  const [userRole] = useState(current?.company_type);

  const renderData = () => {
    switch (userRole) {
      case "Activity":
        return <ActivitiesDashboard />;
      case "Nightlife":
        return <NightLifeDashboard />;
      case "Restaurant":
        return <RestaurantDashboard />;
      default:
        return <RestaurantDashboard />;
    }
  };

  return <>{console.log('current', current)}{renderData()}</>;
};

export default DashboardAction;
