// Booking
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";

// import Kangaroo from "./../assets/images/kangaroo.png";
import BestPrice from "./../assets/images/icons/best-price.png";
import TrustedBy from "./../assets/images/icons/trusted-by.png";
import Australian from "./../assets/images/icons/australian.png";
import Unbeatable from "./../assets/images/icons/unbeatable.png";
import Img1 from "./../assets/images/take-me-there/1.jpg";
import Img2 from "./../assets/images/take-me-there/2.jpg";
import Img3 from "./../assets/images/take-me-there/3.jpg";
import Img4 from "./../assets/images/take-me-there/4.jpg";
import ExpImg1 from "./../assets/images/experiences/1.jpg";
import ExpImg2 from "./../assets/images/experiences/2.jpg";
import ExpImg3 from "./../assets/images/experiences/3.jpg";
import ExpImg4 from "./../assets/images/experiences/4.jpg";
import ExpImg5 from "./../assets/images/experiences/5.jpg";
import ExpImg6 from "./../assets/images/experiences/6.jpg";
// banners images
import AdventureTour2 from "./../assets/images/banners/Adventure-Tour-2.jpg";
import AdventureTour from "./../assets/images/banners/Adventure-Tour.jpg";
import Cairns2 from "./../assets/images/banners/Cairns-2.jpg";
import Cairns from "./../assets/images/banners/Cairns.jpg";
import FunGames2 from "./../assets/images/banners/Fun-Games-2.png";
import FunGames from "./../assets/images/banners/Fun-Games.jpg";
import GoldCoast2 from "./../assets/images/banners/Gold-Coast-2.jpg";
import GoldCoast from "./../assets/images/banners/Gold-Coast.jpg";
import HotDeals2 from "./../assets/images/banners/Hot-Deals-2.jpg";
import HotDeals from "./../assets/images/banners/Hot-Deals.jpg";
import NatureTours2 from "./../assets/images/banners/Nature-Tours-2.jpeg";
import NatureTours from "./../assets/images/banners/Nature-Tours.jpg";
import OutdoorActivities2 from "./../assets/images/banners/Outdoor-Activities-2.jpg";
import OutdoorActivities from "./../assets/images/banners/Outdoor-Activities.jpg";
import PortDouglas2 from "./../assets/images/banners/Port-Douglas-2.png";
import PortDouglas from "./../assets/images/banners/Port-Douglas.png";
import ReefIslandTours2 from "./../assets/images/banners/Reef-Island-Tours-2.jpg";
import ReefIslandTours from "./../assets/images/banners/Reef-Island-Tours.jpg";
import SunshineCoast2 from "./../assets/images/banners/Sunshine-Coast-2.jpg";
import SunshineCoast from "./../assets/images/banners/Sunshine-Coast.jpg";
import BannerComponent from "./BannerComponent";
import BannerSubComponent from "./BannerSubComponent";
import AppConstants from "../appConstants";
import { fetchData } from "../services";
import { generateSku } from "./util/utils";
import TestimonialsComponent from "./TestimonialsComponent";
import StarRating from "./common/StarRating";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1200,
    },
    items: 4,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 575,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  mobile1: {
    breakpoint: {
      max: 991,
      min: 576,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1199,
      min: 992,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
};

const TAKEMETHERE = [
  {
    name: "Gold Coast",
    sku: "/gold-coast",
    image: Img1,
    banners: [GoldCoast2, GoldCoast],
  },
  { name: "Carins", sku: "/carins", image: Img2, banners: [Cairns2, Cairns] },
  {
    name: "Port Douglas",
    sku: "/port-douglas",
    image: Img3,
    banners: [PortDouglas2, PortDouglas],
  },
  {
    name: "Sunshine Coast",
    sku: "/sunshine-coast",
    image: Img4,
    banners: [SunshineCoast2, SunshineCoast],
  },
  {
    name: "Gold Coast",
    sku: "/gold-coast",
    image: Img1,
    banners: [GoldCoast2, GoldCoast],
  },
];

const LOVEEXPERIENCE = [
  {
    name: "Adventure Tours",
    sku: "/adventure-tours",
    image: ExpImg1,
    banners: [AdventureTour2, AdventureTour],
  },
  {
    name: "Outdoor Activities",
    sku: "/outdoor-activities",
    image: ExpImg2,
    banners: [OutdoorActivities2, OutdoorActivities],
  },
  {
    name: "Hot Deals",
    sku: "/hot-deals",
    image: ExpImg3,
    banners: [HotDeals2, HotDeals],
  },
  {
    name: "Fun & Games",
    sku: "/fun-and-games",
    image: ExpImg4,
    banners: [FunGames2, FunGames],
  },
  {
    name: "Zoo & Aquariums",
    sku: "/zoo-and-aquariums",
    image: ExpImg5,
    banners: [NatureTours2, NatureTours],
  },
  {
    name: "Reef & Island Cruises",
    sku: "/reef-and-island-cruises",
    image: ExpImg6,
    banners: [ReefIslandTours2, ReefIslandTours],
  },
];

function HomeComponent() {
  // const location = useLocation();
  const navigate = useNavigate();
  // const { pathname } = location;
  const [banner, setBanner] = useState([]);
  const [topExperience, setTopExperience] = useState([]);
  const [topDeals, setTopDeals] = useState([]);
  const [newExperience, setNewExperience] = useState([]);

  //   const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      //   setLoading(true);
      const resp = await fetchData("getActivityHomeData");
      if (resp.type === "success") {
        // setRecords(resp.payload);
        setBanner(resp.payload.banners);
        setTopExperience(resp.payload.topExperience);
        setTopDeals(resp.payload.topDeals);
        setNewExperience(resp.payload.newExperience);
        // setLoading(false);
        // console.log('resp.payload.topExperience', resp.payload.topExperience);
      }
    }
    getData();
  }, []);

  const handleClickBooking = (tag, item) => {
    const name = generateSku(item.company_name);
    navigate(`/company/${name}`, { state: { companyName: item.company_name } });
  };

  const handleClickViewList = (item) => {
    navigate(`/booking/sub-page${item.sku}`, {
      state: { title: item.name, banners: item.banners },
    });
  };

  return (
    <>
      {/* BANNER */}
      <BannerComponent
        banner={banner}
        title="TOUCH - iT"
        subTitle="Experience it all"
        button={{ title: "Search", link: AppConstants.routes.bookingSearch }}
      />

      {/* BEST PRICE GUARANTEED */}
      <BannerSubComponent />

      {/* TOP EXPERIENCT */}
      <section className="top-experiences te py-5">
        <div className="container">
          <h1 className="heading">Top experiences</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              responsive={responsive}
            >
              {topExperience.map((el, index) => {
                return (
                  <div className="item col-12" key={index}>
                    <div className="join-blocks join-blocks-new">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.activity_name}
                          onClick={() =>
                            handleClickBooking("top-experiences", el)
                          }
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() =>
                          handleClickBooking("top-experiences", el)
                        }
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          margin: "3px 0 0",
                        }}
                      >
                        {el.company_name}
                      </h4>
                      {/* <h4
                        className="cursor-pointer"
                        onClick={() =>
                          handleClickBooking("top-experiences", el)
                        }
                        style={{ fontSize: 14 }}
                      >
                        {el.activity_name}
                      </h4> */}
                      <StarRating rating={el?.total_ratings} />
                      {/* <span className="price">
                        {el?.display_price?.advertisePrice && (
                          <>
                            from <del>${el?.display_price?.advertisePrice}</del>
                          </>
                        )}
                        <ins>${el?.display_price?.price}</ins>
                      </span> */}
                      {el?.reviews && (
                        <span className="price">
                          <ins>Based on {el?.reviews} Reviews</ins>
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>

      {/* EXPERIENCT YOU'LL LOVE */}
      <section className="top-experiences take-me-there experiences py-5">
        <div className="container">
          <h1 className="heading">Experiences you'll love</h1>
          <div className="row g-4 mt-0">
            {LOVEEXPERIENCE.map((el, index) => {
              return (
                <div
                  className="col-lg-4 col-md-4 col-sm-6 col-xs-6"
                  key={index}
                >
                  {/* <Link to={el.sku}> */}
                  <div
                    className="join-blocks cursor-pointer"
                    onClick={() => handleClickViewList(el)}
                  >
                    <div className="join-blocks-img">
                      <img src={el.image} className="img-fluid" alt={el.name} />
                    </div>
                    <h4>{el.name}</h4>
                  </div>
                  {/* </Link> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* TAKE ME THERE */}
      <section className="top-experiences take-me-there py-5 d-none">
        <div className="container">
          <h1 className="heading">Take me there</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              responsive={responsive}
            >
              {TAKEMETHERE.map((el, index) => {
                return (
                  <div className="item" key={index}>
                    {/* <Link to={el.sku}> */}
                    <div
                      className="join-blocks cursor-pointer"
                      onClick={() => handleClickViewList(el)}
                    >
                      <div className="join-blocks-img">
                        <img
                          src={el.image}
                          className="img-fluid"
                          alt={el.name}
                        />
                      </div>
                      <h4>{el.name}</h4>
                    </div>
                    {/* </Link> */}
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>

      {/* TOP DESALS FOR YOU */}
      <section className="top-experiences top-deals py-5">
        <div className="container">
          <h1 className="heading">Top deals for you</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              responsive={responsive}
            >
              {topDeals.map((el, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="join-blocks join-blocks-new">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.activity_name}
                          onClick={() => handleClickBooking("top-deals", el)}
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking("top-deals", el)}
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          margin: "3px 0 0",
                        }}
                      >
                        {el.company_name}
                      </h4>
                      {/* <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking("top-deals", el)}
                        style={{ fontSize: 14 }}
                      >
                        {el.activity_name}
                      </h4> */}
                      <StarRating rating={el?.total_ratings} />
                      {/* <span className="price">
                        {el?.display_price?.advertisePrice && (
                          <>
                            from <del>${el?.display_price?.advertisePrice}</del>
                          </>
                        )}
                        <ins>${el?.display_price?.price}</ins>
                      </span> */}
                      {el?.reviews && (
                        <span className="price">
                          <ins>Based on {el?.reviews} Reviews</ins>
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>

      {/* TESTIMONIALS */}
      <section className="top-experiences testimonials py-5">
        <TestimonialsComponent />
      </section>

      {/* TOP NEW EXPERIENCT */}
      <section className="top-experiences top-deals pt-5">
        <div className="container">
          <h1 className="heading">Top new experiences</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              responsive={responsive}
            >
              {newExperience.map((el, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="join-blocks join-blocks-new">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.activity_name}
                          onClick={() =>
                            handleClickBooking("new-experiences", el)
                          }
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() =>
                          handleClickBooking("new-experiences", el)
                        }
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          margin: "3px 0 0",
                        }}
                      >
                        {el.company_name}
                      </h4>
                      {/* <h4
                        className="cursor-pointer"
                        onClick={() =>
                          handleClickBooking("new-experiences", el)
                        }
                        style={{ fontSize: 14 }}
                      >
                        {el.activity_name}
                      </h4> */}
                      <StarRating rating={el?.total_ratings} />
                      {/* <span className="price">
                        {el?.display_price?.advertisePrice && (
                          <>
                            from <del>${el?.display_price?.advertisePrice}</del>
                          </>
                        )}
                        <ins>${el?.display_price?.price}</ins>
                      </span> */}
                      {el?.reviews && (
                        <span className="price">
                          <ins>Based on {el?.reviews} Reviews</ins>
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeComponent;
