import React, { useEffect, useState } from "react";
import { handleNumericInput } from "../util/utils";
import { useLocation } from "react-router-dom";
import { QuestionCircle } from "react-bootstrap-icons";
import VideoTutorialPopUp from "../admin/VideoTutorialPopUp";
import AppConstants from "../../appConstants";

const CostPerPersonSignupVideo = AppConstants.tutorial.costPerPerson;

function CostPerPersonPage({
  handleSubmit,
  loading,
  stepValue,
  setStep,
  priceDataList,
  SideBar,
  btnLabel,
  calanderType,
}) {
  const location = useLocation();
  const { pathname } = location;
  const [priceData, setpriceData] = useState([]);
  const [videoURL, setVideoURL] = useState("");
  const showTutorialVideo = [
    AppConstants.routes.admin.updateCostPerson,
    AppConstants.routes.admin.actiityCreateCompany,
    AppConstants.routes.tour.createCompany,
  ].includes(pathname);

  // const [advertisedPrice] = useState(companyDetails?.advertised_price || 0);

  useEffect(() => {
    async function fetchData() {
      const update = priceDataList.map((item) => {
        return {
          ...item,
          ...(item.label === undefined && { label: item.field }),
          ...(item.price === undefined && { price: item.single_price }),
          ...(item.id && item.isGroup === undefined && { isGroup: false }),
          ...(item.id && item.isDisplay === undefined && { isDisplay: true }),
        };
      });
      setpriceData(update);
      window.scrollTo(0, 0);
    }
    fetchData();
  }, [priceDataList]);

  const handleChange = (id, value) => {
    setpriceData(
      priceData.map((field) =>
        field.id === id ? { ...field, touchIdLabel: value, quantity: 0 } : field
      )
    );
  };

  const handleChangeAdvPrice = (id, value) => {
    setpriceData(
      priceData.map((field) =>
        field.id === id ? { ...field, advertisePrice: value } : field
      )
    );
  };

  const handleSubmitForm = async () => {
    const data = {
      priceOptions: priceData,
      ...(stepValue && {
        profileLavel: stepValue,
      }),
      // advertisedPrice,
    };

    handleSubmit(data, stepValue);
  };

  const handleChangeChecked = (event, id) => {
    const checked = event.target.checked || false;
    setpriceData(
      priceData.map((field) =>
        field.id === id ? { ...field, isDisplay: checked } : field
      )
    );
  };

  const handleChangeCheckedGroup = (event, id) => {
    const checked = event.target.checked || false;
    setpriceData(
      priceData.map((field) =>
        field.id === id ? { ...field, isGroup: checked } : field
      )
    );
  };

  const handleClickTutorial = () => {
    setVideoURL(CostPerPersonSignupVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="cost-per-person-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <SideBar setStep={setStep} />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>What Is The Cost Per Person?</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    {showTutorialVideo && <QuestionCircle />}
                  </span>
                </div>

                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                    Please enter the price for each category:
                  </div>
                  {priceData.length > 0 && (
                    <>
                      {priceData.map((el) => (
                        <div key={el.id}>
                          <div className="row">
                            <div className="col-sm-1 col-md-1">
                              <label htmlFor="adult">Display:</label>
                              <input
                                style={{ marginTop: 5 }}
                                type="checkbox"
                                name="dayOfWeek"
                                id={el.label}
                                checked={el.isDisplay || false}
                                onChange={(e) => {
                                  handleChangeChecked(e, el.id);
                                }}
                              />
                            </div>
                            <div className="col-sm-1 col-md-1">
                              <label htmlFor="adult">Group?:</label>
                              <input
                                style={{ marginTop: 5 }}
                                type="checkbox"
                                name="dayOfWeek"
                                id={el.label}
                                checked={el.isGroup || false}
                                onChange={(e) => {
                                  handleChangeCheckedGroup(e, el.id);
                                }}
                              />
                            </div>
                            <div className="col-sm-2 col-md-2">
                              <div className="form-group">
                                <label htmlFor="adult" className="">
                                  ID:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={el?.id}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2 col-md-2">
                              <div className="form-group">
                                <label htmlFor="1adult2Children" className="">
                                  {calanderType} Label:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={
                                    calanderType === "Rezdy"
                                      ? el?.label
                                      : el?.name
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-2 col-md-2">
                              <div className="form-group">
                                <label htmlFor="2adult2Child" className="">
                                  Price:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={
                                    calanderType === "Rezdy"
                                      ? el?.price
                                      : el?.single_price
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-2 col-md-2">
                              <div className="form-group">
                                <label htmlFor="2adult2Child" className="">
                                  Adv. Price:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={el?.advertisePrice || ""}
                                  onChange={(e) => {
                                    const inputValue = handleNumericInput(
                                      e.target.value
                                    );
                                    if (
                                      inputValue !== null &&
                                      inputValue.length < 8
                                    ) {
                                      handleChangeAdvPrice(el.id, inputValue);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2 col-md-2">
                              <div className="form-group">
                                <label htmlFor="2adult2Child" className="">
                                  Touch. Label:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  value={el?.touchIdLabel || ""}
                                  onChange={(e) =>
                                    handleChange(el.id, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {/* <hr /> */}
                        </div>
                      ))}
                      <div className="btn-wrapper text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-custom"
                          onClick={handleSubmitForm}
                          disabled={loading || false}
                        >
                          {loading ? "Loading..." : btnLabel}
                        </button>
                      </div>
                    </>
                  )}
                  {priceData.length === 0 && (
                    <div className="text-center">Loading...</div>
                  )}
                </div>
              </div>
              {/* <div className="adv-price-sec my-4 pb-4">
                <div className="main-heading">Advertised Price:</div>
                <div className="row">
                  <div className="col-md-7">
                    <div className="fs-18 fw-medium mt-2 mb-2">
                      The prediscount price shown on the search result page
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group m-0">
                      <input
                        type="text"
                        className="form-control"
                        style={{ maxWidth: "500px" }}
                        disabled
                        value={advertisedPrice}
                      />
                    </div>
                  </div>
                </div>

                <div className="btn-wrapper text-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-custom"
                    onClick={handleSubmitForm}
                    disabled={loading || false}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default CostPerPersonPage;
